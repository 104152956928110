export function openNewTab(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

export function isMobile() {
  const mobile = window.innerWidth <= 500;
  return mobile;
}

export function isTablet() {
  const tablet = window.innerWidth <= 960;
  return tablet;
}

