import {ui_de, texts_de} from './i18n.de';
import {ui_en, texts_en} from './i18n.en';
import {ui_fr, texts_fr} from './i18n.fr';
import { saveSettings, settings } from './settings';
import { pageinit_de } from './tour.de';
import { pageinit_en } from './tour.en';
import { pageinit_fr } from './tour.fr';
let ui = {};
let texts = {};
let pageinit = '';

export let uiLanguage = identifyLanguage();
setUiLanguage(uiLanguage);

function identifyLanguage() {
  let lang = settings.uilanguage;
  if (!lang) {
    lang = navigator.language || navigator.userLanguage;
  }
  if (lang === 'de' || lang.startsWith('de-')) {
    lang = 'de';
  } else if (lang === 'fr' || lang.startsWith('fr-')) {
    lang = 'fr';
  } else {
    lang = 'en';
  }
  return lang;
}

export function setUiLanguage(iso6391) {
  const old = uiLanguage;
  uiLanguage = iso6391;
  settings.uilanguage = uiLanguage;
  saveSettings();

  const deFlag = document.querySelector('.set-language-de');
  const enFlag = document.querySelector('.set-language-en');
  const frFlag = document.querySelector('.set-language-fr');
  if (iso6391 === 'de') {
    ui = ui_de;
    texts = texts_de;
    pageinit = pageinit_de;
    if (enFlag && deFlag && frFlag) {      
      enFlag.disabled = false;
      deFlag.disabled = true;
      frFlag.disabled = false;
    }
  } else if (iso6391 === 'en') {
    ui = ui_en;
    texts = texts_en;
    pageinit = pageinit_en;
    if (enFlag && deFlag && frFlag) {      
      deFlag.disabled = false;
      enFlag.disabled = true;
      frFlag.disabled = false;
    }
  } else if (iso6391 === 'fr') {
    ui = ui_fr;
    texts = texts_fr;
    pageinit = pageinit_fr;
    if (enFlag && deFlag && frFlag) {      
      deFlag.disabled = false;
      enFlag.disabled = false;
      frFlag.disabled = true;
    }
  } else {
    throw 'Unsupported language ' + iso6391;
  }
  return old;
}

export function translateUi(element) {
  element = element || document;
  for (const [key, value] of Object.entries(ui)) {
    if (key.endsWith(':title')) {
      translateAttribute('title', element, key, value);
    } else if (key.endsWith(':placeholder')) {
      translateAttribute('placeholder', element, key, value);
    } else {
      translateContent(element, key, value);
    }
  }
}

function translateContent(element, i18nKey, i18nValue) {
  const els = element.querySelectorAll(i18nKey);
  if (els.length === 0) {
    return;
  }
  els.forEach(el => {
    const title = el.querySelector('.title');
    if (title) {
      title.innerHTML = i18nValue;
    } else {
      el.innerHTML = i18nValue;
    }
  });
}

function translateAttribute(attr, element, i18nKeyWithSuffix, i18nValue) {
  const i18nKey = i18nKeyWithSuffix.substring(0, i18nKeyWithSuffix.length - attr.length - 1);
  const els = element.querySelectorAll(i18nKey);
  if (els.length === 0) {
    return;
  }
  els.forEach(el => {
    el.setAttribute(attr, i18nValue);
  });
}

export function translate(text) {
  const trns = texts[text];
  if (!trns) {
    console.log('Translation missing: ' + text);
  }
  return trns || text;
}