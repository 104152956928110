import { endOfWeek, fmtDate, fmtTime, refmtTime } from './dates';
import { showWeek } from './index';
import { saveTodos } from './storage';
import { createItem, todoMap, updateItem, allTagsInUse } from './items';
import jquery from 'jquery';
import datepicker from 'bootstrap-datepicker';
import { todoTitle, updateSearchData } from './search';
import { modal } from './modal';
import { addModalTour, editModalTour } from './tour';
import { uiLanguage } from './i18n';
import { parse } from 'tough-cookie';
import { applyAutotags, initTagify, isAutoTag, selectedTags } from './tags';
import { addChildEvent } from './events';

jquery.fn.datepicker.dates.de = {
  days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
  daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  today: "Heute",
  monthsTitle: "Monate",
  clear: "Löschen",
  weekStart: 1,
  format: "dd.mm.yyyy"
};
let addTagger;
let editTagger;
let editSeriesTagger;

export function initAddEditModals() {
  prepareEditModal();
  prepareEditSeriesModal();
  prepareAddModal();
  initDatePicker();
  initTagEdit();
}

export function openAddTodoModal() {
  resetTodo();
  const formElement = document.getElementById('add-todo-form');
  formElement.querySelector('[name="tags"]').value = JSON.stringify(selectedTags);
  formElement.querySelector('[name="duration"]').value = '15m';
  addTagger.whitelist = allTagsInUse();
  jquery('#addTodoModal').modal('show');
}

let lastEditTodoId = false;
let lastEditModalId = false;

export function openLastEditModal() {
  if (lastEditTodoId) {
    closeEditTodo();
    openEditModalForTodo(lastEditTodoId, lastEditModalId);
  }
}

export function openEditModalForTodo(todoId, modalId = 'editTodoModal') {
  lastEditTodoId = todoId;
  lastEditModalId = modalId;

  const tagsInUse = allTagsInUse();
  editTagger.whitelist = tagsInUse;
  editSeriesTagger.whitelist = tagsInUse;
  const item = todoMap.get(todoId);
  const delBtn = document.getElementById('deleteTodoSeries');
  const editBtn = document.getElementById('editTodoSeries');
  if (item.instanceOf) {
    delBtn.style.display = 'inline';
    delBtn.classList.add('visible');
    editBtn.style.display = 'inline';
    editBtn.classList.add('visible');
  } else {
    delBtn.style.display = 'none';
    delBtn.classList.remove('visible');
    editBtn.style.display = 'none';
    editBtn.classList.remove('visible');
  }
  const form = document.getElementById(modalId).querySelector('form');
  form.dataset.todo = todoId;
  form.querySelector('[name="title"]').value = item.title;
  const tags = [];
  if (item.tags) {
    item.tags.forEach(tag => {
      if (!isAutoTag(tag)) {
        const tagObject = {value: tag};
        tags.push(tagObject);
      }
    });
  }
  form.querySelector('[name="tags"]').value = JSON.stringify(tags);
  form.querySelector('[name="due"]').checked = item.due;
  if (item.due) {
    form.querySelector('[name="due-type"]').value = item.due.type;
    form.querySelector('[name="due-date"]').value = fmtDate(item.due.date);
    if (item.due.time) {
      form.querySelector('[name="due-time"]').value = fmtTime(item.due.time);
    } else {
      form.querySelector('[name="due-time"]').value = '';
    }
  } else {
    form.querySelector('[name="due-type"]').value = 'day';
    form.querySelector('[name="due-date"]').value = fmtDate(new Date());
    form.querySelector('[name="due-time"]').value = '';
  }
  form.querySelector('[name="repeat"]').checked = item.repeat;
  if (item.repeat) {
    form.querySelector('[name="repeat-every"]').value = item.repeat.every;
    form.querySelector('[name="repeat-scope"]').value = item.repeat.scope;
    form.querySelector('[name="repeat-prio"]').value = item.repeat.prio || '';
  } else {
    form.querySelector('[name="repeat-every"]').value = 1;
    form.querySelector('[name="repeat-scope"]').value = 'day';
    form.querySelector('[name="repeat-prio"]').value = item.priority || '';
  }
  form.querySelector('[name="duration"]').value = item.duration || '';
  form.querySelector('[name="notes"]').value = item.notes || '';
  jquery('#editTodoDate').datepicker('update');
  jquery('#' + modalId).modal('show');
}

function closeAddTodo() {
  const formElement = document.getElementById('add-todo-form');
  formElement.querySelector('[name="tags"]').value = '';
  jquery('#addTodoModal').modal('hide');
}

function closeEditTodo() {
  const formElement = document.getElementById('edit-todo-form');
  formElement.querySelector('[name="tags"]').value = '';
  jquery('#editTodoModal').modal('hide');
}

function closeEditTodoSeries() {
  jquery('#editTodoSeriesModal').modal('hide');
}

function prepareEditModal() {
  let modalHtml = document.getElementById('addTodoModal').innerHTML;
  modalHtml = modalHtml.replaceAll('addTodo', 'editTodo');
  modalHtml = modalHtml.replaceAll('add-todo', 'edit-todo');
  modalHtml = modalHtml.replaceAll('Add Todo', 'Edit Todo');
  document.getElementById('editTodoModal').innerHTML = modalHtml;

  document.getElementById('deleteTodo').addEventListener('click', deleteTodo);
  document.getElementById('deleteTodoSeries').addEventListener('click', deleteTodoSeries);
  document.getElementById('editTodoSeries').addEventListener('click', editTodoSeries);
  document.getElementById('editTodoSubmit').addEventListener('click', editTodo);
  let inTagEditor = false;
  document.getElementById('editTodoModal').addEventListener('keydown', function (evt) {
    if (evt.code === 'Enter' && document.activeElement.classList.contains('tagify__input')) {
      inTagEditor = document.activeElement.textContent.length > 0;
    }
  });
  document.getElementById('editTodoModal').addEventListener('keyup', function (evt) {
    // Enter either together with Ctrl or not in textarea nor tag editor
    if (evt.code === 'Enter' && (evt.ctrlKey || 
      (document.activeElement.tagName.toLowerCase() !== 'textarea' && !inTagEditor))) {
      editTodo(evt);
    }
    inTagEditor = false;
  });
  jquery('#editTodoModal').on('shown.bs.modal', function () {
    const el = document.getElementById('editTodoTitle');
    focusInput(el);
    editModalTour();
  });
  jquery('#editTodoModal').on('hidden.bs.modal', function () {
    const searchVisible = jquery('#searchModal').hasClass('show');
    if (searchVisible) {
      updateSearchData();
    }
  });

}

function prepareEditSeriesModal() {
  let modalHtml = document.getElementById('addTodoModal').innerHTML;
  modalHtml = modalHtml.replaceAll('addTodo', 'editTodoSeries');
  modalHtml = modalHtml.replaceAll('add-todo', 'edit-todo-series');
  modalHtml = modalHtml.replaceAll('Add Todo', 'Edit Todo Series');
  const modal = document.getElementById('editTodoSeriesModal');
  modal.innerHTML = modalHtml;

  modal.querySelector('#resetTodo').remove();
  modal.querySelector('#deleteTodo').remove();
  modal.querySelector('#deleteTodoSeries').remove();
  modal.querySelector('#editTodoSeries').remove();
  document.getElementById('editTodoSeries').addEventListener('click', editTodoSeries);
  document.getElementById('editTodoSeriesSubmit').addEventListener('click', submitEditTodoSeries);
  let inTagEditor = false;
  document.getElementById('editTodoSeriesModal').addEventListener('keydown', function (evt) {
    if (evt.code === 'Enter' && document.activeElement.classList.contains('tagify__input')) {
      inTagEditor = document.activeElement.textContent.length > 0;
    }
  });
  document.getElementById('editTodoSeriesModal').addEventListener('keyup', function (evt) {
    if (evt.code === 'Enter' && (evt.ctrlKey || (document.activeElement.tagName.toLowerCase() !== 'textarea' && !inTagEditor))) {
      submitEditTodoSeries(evt);
    }
    inTagEditor = false;
  });
  jquery('#editTodoSeriesModal').on('shown.bs.modal', function () {
    const el = document.getElementById('editTodoSeriesTitle');
    focusInput(el);
  });
  jquery('#editTodoSeriesModal').on('hidden.bs.modal', function () {
    const searchVisible = jquery('#searchModal').hasClass('show');
    if (searchVisible) {
      updateSearchData();
    }
  });

}

export function focusInput(el) {
  el.focus();
  el.setSelectionRange(0, el.value.length);
}
let lastTaPos = 0;
let lastTaSelEnd = 0;

function prepareAddModal() {
  document.getElementById('addTodoDate').value = fmtDate(new Date());
  jquery('#addTodoModal').on('shown.bs.modal', function () {
    const el = document.getElementById('addTodoTitle');
    focusInput(el);
    addModalTour();
  });

  const form = document.getElementById('addTodoModal');
  form.querySelector('#editTodoSeries').remove();
  form.querySelector('#resetTodo').addEventListener('click', resetTodo);
  document.getElementById('addTodoSubmit').addEventListener('click', addTodo);
  let inTagEditor = false;
  document.getElementById('addTodoModal').addEventListener('keydown', function (evt) {
    if (evt.code === 'Enter' && document.activeElement.classList.contains('tagify__input')) {
      inTagEditor = document.activeElement.textContent.length > 0;
    }
  });
  document.getElementById('addTodoModal').addEventListener('keyup', function (evt) {
    // Enter either together with Ctrl or not in textarea nor tag editor
    if (evt.key === 'Enter' && (evt.ctrlKey || 
      (document.activeElement.tagName.toLowerCase() !== 'textarea' && !inTagEditor))) {   
      addTodo(evt);
    }
    inTagEditor = false;
  });

  addChildEvent(document, 'focusout', 'textarea', (evt) => {
    const taEl = evt.target;
    lastTaPos = taEl.selectionStart;
    lastTaSelEnd = taEl.selectionEnd;
  });
  addChildEvent(document, 'keyup', 'textarea', (evt) => {
    if (evt.key === 'Enter') {
      const taEl = evt.target;
      lastTaPos = taEl.selectionStart;
      lastTaSelEnd = taEl.selectionEnd;
      const pre = taEl.value.substring(0, lastTaPos);
      const nl = pre.lastIndexOf('\n');
      const preNl = pre.lastIndexOf('\n', nl - 1);
      const prevLineStart = pre.substring(preNl+1, nl);
      const asteriskMatch = prevLineStart.match(/^(\s*)\*\s/);
      const minusMatch = prevLineStart.match(/^(\s*)-\s/);
      const numMatch = prevLineStart.match(/^(\s*)\d+\.\s/);
      if (asteriskMatch) {
        injectLi(taEl, asteriskMatch[1]  +'*');
      } else if (minusMatch) {
        injectLi(taEl, minusMatch[1] + '-');
      } else if (numMatch) {
        injectLi(taEl, numMatch[1] + '1.');
      }
    }
  });
  addChildEvent(document, 'click', '.btn-ul', (evt) => {
    const taEl = evt.target.closest('form').querySelector('textarea');
    injectLi(taEl, '*');
  });
  addChildEvent(document, 'click', '.btn-ol', (evt) => {
    const taEl = evt.target.closest('form').querySelector('textarea');
    injectLi(taEl, '1.');
  });
  addChildEvent(document, 'click', '.btn-indent-left', (evt) => {
    const taEl = evt.target.closest('form').querySelector('textarea');
    indentLeft(taEl);
  });
  addChildEvent(document, 'click', '.btn-indent-right', (evt) => {
    const taEl = evt.target.closest('form').querySelector('textarea');
    indentRight(taEl);
  });
  addChildEvent(document, 'click', '.format-ribbon button', (evt) => {
    const cls = evt.target.closest('button').classList;
    if (cls.contains('btn-code')) {
      injectMarkup(evt, '`');
    } else if (cls.contains('btn-bold')) {
      injectMarkup(evt, '**');
    } else if (cls.contains('btn-italic')) {
      injectMarkup(evt, '_');
    } else if (cls.contains('btn-strike')) {
      injectMarkup(evt, '~~');
    } else if (cls.contains('btn-url')) {
      injectMarkup(evt, '[', ']()');
    }
  });
}

function injectMarkup(evt, prefix, suffix = prefix) {
  const taEl = evt.target.closest('form').querySelector('textarea');
  let text = taEl.value;
  if (lastTaPos === lastTaSelEnd) {
    text = text.substring(0, lastTaPos) + prefix + suffix + text.substring(lastTaPos); 
    lastTaPos += prefix.length;
    lastTaSelEnd += prefix.length;
  } else {
    const pre = text.substring(0, lastTaPos);
    const inner = text.substring(lastTaPos, lastTaSelEnd);
    const post = text.substring(lastTaSelEnd);
    text = pre + prefix + inner + suffix + post;
    lastTaSelEnd += prefix.length + suffix.length;
  }
  focusTextarea(taEl, text, lastTaPos, lastTaSelEnd);
}

function calcX(taEl, pos) {
  const before = taEl.value.substring(0, pos);
  const nl = before.lastIndexOf('\n');
  const x = pos - nl - 1;
  return x;
}

function calcY(taEl, pos) {
  const before = taEl.value.substring(0, pos);
  const y = before.split('\n').length - 1;
  return y;
}

function indentLeft(taEl) {
  let text = taEl.value;
  const len = text.length;
  const x = calcX(taEl, lastTaPos);
  const currentLineStart = lastTaPos - x;
  let currentLine = text.substring(currentLineStart);
  if (currentLine.startsWith('  ')) {
    text = removeAt(text, currentLineStart, 2);
  }
  focusTextarea(taEl, text, lastTaPos);
}

function indentRight(taEl) {
  let text = taEl.value;
  const len = text.length;
  const x = calcX(taEl, lastTaPos);
  const currentLineStart = lastTaPos - x;
  text = insertAt(text, currentLineStart, '  ');
  focusTextarea(taEl, text, lastTaPos);
}

function injectLi(taEl, type) {
  let text = taEl.value;
  const len = text.length;
  const x = calcX(taEl, lastTaPos);
  const currentLineStart = lastTaPos - x;
  let currentLine = text.substring(currentLineStart);
  if (!currentLine.startsWith(type + ' ')) {
    // current line does not start with list sign, so insert it
    text = insertAt(text, currentLineStart, type + ' ');
  } else if (lastTaPos < len - 1 || x > 0) {
    // current line starts with list sign, we are not at the end of the text or not at the beginning of a line => line break and insert sign
    text = insertAt(text, lastTaPos, '\n' + type + ' ');
  } else {
    // we are at the very last position and at the beginning of the line, just add list sign
    text += type + ' ';
    lastTaPos += type.length + 1;
  }
  focusTextarea(taEl, text, lastTaPos);
}

function insertAt(text, at, toInsert) {
  text = text.substring(0, at) + toInsert + text.substring(at); 
  lastTaPos += toInsert.length;
  return text;
}

function removeAt(text, at, num) {
  text = text.substring(0, at) + text.substring(at + num); 
  lastTaPos -= num;
  return text;
}

function focusTextarea(taEl, text, pos, end = pos) {
  taEl.value = text;
  taEl.focus();
  taEl.setSelectionRange(pos, end);
}

function initDatePicker() {
  jquery('#addTodoDate,#editTodoDate').datepicker({
    format: 'yyyy-mm-dd',
    autoclose: true,
    calendarWeeks: true,
    todayBtn: true,
    language: uiLanguage,
    todayHighlight: true    
  }).on('changeDate', function (evt) {
    const el = jquery(this);
    const input = el[0];
    const newState = input.value.length > 0;
    input.closest('form').querySelector('[name="due"]').checked = newState;
  });
}

function initTagEdit() {
  addTagger = initTagify('#addTodoTags');
  editTagger = initTagify('#editTodoTags');
  editSeriesTagger = initTagify('#editTodoSeriesTags');
}

function addTodo(evt) {
  const form = document.getElementById('add-todo-form');
  if (form.checkValidity() === false) {
    evt.preventDefault();
    evt.stopPropagation();
    form.classList.add('was-validated');
  } else {
    const formElement = document.getElementById('add-todo-form');
    const title = formElement.querySelector('[name="title"]').value;
    const due = formElement.querySelector('[name="due"]').checked;
    const dueType = formElement.querySelector('[name="due-type"]').value;
    const dueDate = formElement.querySelector('[name="due-date"]').value;
    let dueTime = formElement.querySelector('[name="due-time"]').value;
    if (dueTime.trim().length === 0) {
      dueTime = null;
    } else {
      dueTime = refmtTime(dueTime);
    }
    const repeat = formElement.querySelector('[name="repeat"]').checked;
    const repeatEvery = formElement.querySelector('[name="repeat-every"]').value;
    const repeatScope = formElement.querySelector('[name="repeat-scope"]').value;
    const repeatPrio = formElement.querySelector('[name="repeat-prio"]').value;
    const duration = formElement.querySelector('[name="duration"]').value;
    const notes = formElement.querySelector('[name="notes"]').value;
    const item = createItem(title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes);
    lastEditTodoId = item.id;
    lastEditModalId = false;

    const pureTags = parseFormTags(formElement);
    item.tags = pureTags;
    applyAutotags(item);
    saveTodos();
    showWeek();
    closeAddTodo();
    form.classList.remove('was-validated');
  }
}

function parseFormTags(formElement) {
  const tagString = formElement.querySelector('[name="tags"]').value;
  let tags = [];
  if (tagString && tagString.length > 0) {
    try {
      tags = JSON.parse(tagString);
      console.log("Parsed: " + tags);
    } catch (exc) {
      console.log(tagString);
      console.log("Tag parsing failed:" + exc);
    }
  }
  const pureTags = [];
  tags.forEach(value => {
    const tag = value.value;
    pureTags.push(tag);
  });
  return pureTags;
}

function deleteTodo(evt) {
  const form = document.getElementById('edit-todo-form');
  const id = form.dataset.todo;
  const item = todoMap.get(id);
  item.deleted = new Date();
  saveTodos();
  showWeek();
  closeEditTodo();
}

function resetTodo(evt) {
  const formElement = document.getElementById('add-todo-form');
  formElement.classList.remove('was-validated');
  formElement.querySelector('[name="title"]').value = '';
  formElement.querySelector('[name="tags"]').value = '';
  formElement.querySelector('[name="repeat"]').checked = false;
  formElement.querySelector('[name="duration"]').value = '';
  formElement.querySelector('[name="notes"]').value = '';
  formElement.querySelector('[name="repeat-prio"]').value = '';
  jquery('#addTodoDate').datepicker('update');
}

function actualDeleteTodoSeries(modalDom) {
  const which = modalDom.querySelector('select option:checked').value;
  const id = modalDom.dataset.todo;
  const item = todoMap.get(id);
  let laterThan;
  if (which === 'future') {
    if (!item.due) {
      throw "Selected item does not have a due date.";
    }
    laterThan = new Date(item.due.date);
  } else if (which === 'all') {
    laterThan = new Date(0);
  } else {
    throw "Unknown type: " + which;
  }  

  const repeatId = item.instanceOf || item.wasInstanceOf;
  const repeatItem = todoMap.get(repeatId);
  repeatItem.deleted = new Date();
  for (let todo of todoMap.values()) {
    if ((todo.instanceOf && todo.instanceOf === repeatId) ||
      (todo.wasInstanceOf && todo.wasInstanceOf === repeatId)) {
      if (todo.due && new Date(todo.due.date) >= laterThan) {
        todo.deleted = new Date();
      }
    }
  }
  saveTodos();
  showWeek();
}

function editTodoSeries(evt) {
  const form = document.getElementById('edit-todo-form');
  const id = form.dataset.todo;
  const item = todoMap.get(id);
  const seriesId = item.instanceOf;
  closeEditTodo();
  openEditModalForTodo(seriesId, 'editTodoSeriesModal');
}

function deleteTodoSeries(evt) {
  const form = document.getElementById('edit-todo-form');
  const id = form.dataset.todo;
  const item = todoMap.get(id);
  closeEditTodo();
  modal('delete-series', 'Delete Series', 
    `<span class="before">Delete todo</span> "${todoTitle(item)}" <span class="after">and its recurrences:</span> 
    <select class="select-delete-series custom-select">
    <option selected value="future">Delete only this and later instances</option>
    <option value="all">Delete all instances</option>
  </select>`, 
    'Delete Series', actualDeleteTodoSeries, 'Cancel', null, item);
}

function submitEditTodoSeries(evt) {
  const form = document.getElementById('edit-todo-series-form');
  if (form.checkValidity() === false) {
    evt.preventDefault();
    evt.stopPropagation();
    form.classList.add('was-validated');
  } else {
    const formElement = document.getElementById('edit-todo-series-form');
    const title = formElement.querySelector('[name="title"]').value;
    const due = formElement.querySelector('[name="due"]').checked;
    const dueType = formElement.querySelector('[name="due-type"]').value;
    const dueDate = formElement.querySelector('[name="due-date"]').value;
    let dueTime = formElement.querySelector('[name="due-time"]').value;
    if (dueTime.trim().length === 0) {
      dueTime = null;
    } else {
      dueTime = fmtTime(dueTime);
    }
    const repeat = formElement.querySelector('[name="repeat"]').checked;
    const repeatEvery = formElement.querySelector('[name="repeat-every"]').value;
    const repeatScope = formElement.querySelector('[name="repeat-scope"]').value;
    const repeatPrio = formElement.querySelector('[name="repeat-prio"]').value;
    const duration = formElement.querySelector('[name="duration"]').value;
    const notes = formElement.querySelector('[name="notes"]').value;
    const id = formElement.dataset.todo;
    const item = todoMap.get(id);
    updateItem(item, title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes);
    const pureTags = parseFormTags(formElement);
    item.tags = pureTags;
    applyAutotags(item);

    const instances = [];
    for (let td of todoMap.values()){
      if (td.instanceOf === id) {
        instances.push(td.id);
      }
    }
    instances.forEach(id => todoMap.delete(id));
    saveTodos();
    showWeek();
    closeEditTodoSeries();
    form.classList.remove('was-validated');
  }
}

function editTodo(evt) {
  const form = document.getElementById('edit-todo-form');
  if (form.checkValidity() === false) {
    evt.preventDefault();
    evt.stopPropagation();
    form.classList.add('was-validated');
  } else {
    const formElement = document.getElementById('edit-todo-form');
    const title = formElement.querySelector('[name="title"]').value;
    const due = formElement.querySelector('[name="due"]').checked;
    const dueType = formElement.querySelector('[name="due-type"]').value;
    const dueDate = formElement.querySelector('[name="due-date"]').value;
    let dueTime = formElement.querySelector('[name="due-time"]').value;
    if (dueTime.trim().length === 0) {
      dueTime = null;
    } else {
      dueTime = fmtTime(dueTime);
    }
    const repeat = formElement.querySelector('[name="repeat"]').checked;
    const repeatEvery = formElement.querySelector('[name="repeat-every"]').value;
    const repeatScope = formElement.querySelector('[name="repeat-scope"]').value;
    const repeatPrio = formElement.querySelector('[name="repeat-prio"]').value;
    const duration = formElement.querySelector('[name="duration"]').value;
    const notes = formElement.querySelector('[name="notes"]').value;
    const id = formElement.dataset.todo;
    const item = todoMap.get(id);
    updateItem(item, title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes);
    const pureTags = parseFormTags(formElement);
    item.tags = pureTags;
    applyAutotags(item);
    saveTodos();
    showWeek();
    closeEditTodo();
    form.classList.remove('was-validated');
  }
}