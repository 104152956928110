import 'bootswatch/dist/litera/bootstrap.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'swiper/css';
import 'swiper/css/effect-cube';
import './index.css';
import { registerSW } from 'virtual:pwa-register';
registerSW({ immediate: true });

import {saveSettings, settings} from './settings';
import {fmtDate, endOfWeek, isDateElapsed, isDateNear, startOfWeek, titleDateDay, titleDateWeek, titleDateMonth, titleDateYear, startOfMonth, startOfYear, startOfDay, cw, plusDay, durationToMinutes, isThisWeek} from './dates';
import {formatDuration, purgeTodos, repeatedTodos, singleTodos, updateTodos} from './items';
import {syncPeek, initDropbox, chooseDropboxFileDialog, updateSyncFilename} from './dropbox-sync';
import {chooseCalendars, googleAuthInit, startUpdateInterval, stopUpdateInterval, updateMenuCalendars} from './google-calendar';
import { initKeyboard } from './keyboard';
import { closeHamburger, dragDrop, initAllEvents } from './events';
import { appendNavHeading, appendNavItem, appendNavItemConnect, appendNavItemOpt, appendNavSep, bucketHtml, col, createItemDateTitle, createItemHtml, loadScript, row, setTitleKeepAttention, titleRemoveImplicitAttention } from './html';
import { initAddEditModals } from './add-edit-modals';

import jquery from 'jquery';
import bootstrap from 'bootstrap';

import { translate, translateUi } from './i18n';
import { isMobile, isTablet } from './browser';
import { allPopovers, applyBucketWatermark, hide, reshow } from './ui';
import { tioVersion, tioDate } from './version';
import { initUpdater } from './updater';
import Swiper from 'swiper';
import { nextWeek, previousWeek, start, current } from './nav';
import { millisPerWeek } from './dates';
import { isEnabled } from './features';
import * as pageinit from './pageinit';
import { trycatch } from './utils';
import { setBadge } from './badge';
import { hideAllNoHideToasts } from './alerts';
import { mainTour } from './tour';
import { countTags, countTodoTags, matchesSelectedTag, mostFrequentTags, mostFrequentTagsButtons, mostFrequentTagsOf, tagColorButton } from './tags';
import { startRepeatTimer } from './repeattimers';
import { o365AuthInit } from './office365-calendar';

if (isEnabled('mobile-console')) {
  loadScript('https://unpkg.com/vconsole@latest/dist/vconsole.min.js').onload = function () {
    setTimeout(() => {new window.VConsole();}, 1000);
  };
}
pageinit.globalErrorHandler();
pageinit.enableDisableParameters();

const essentialLayout = [
  ["overdue", "today", "tomorrow", "this-week-strict", "this-month-strict", "eventually"]
];
const desktopLayout = [
  ["monday", "tuesday", "wednesday", "thursday", "friday", "this-weekend"], 
  ["overdue", "this-week", "next-week", "this-month", "eventually"]
];
const tabletLayout = [
  ["overdue", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"], 
  ["this-week-strict", "this-month-strict", "this-year-strict", "eventually"]
];
const mobileLayout = [
  ["overdue", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", 
  "this-week-strict", "this-month-strict", "this-year-strict", "eventually"]
];
const bucketsCalcOrder = [
  'today', 'tomorrow', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',
  'this-weekend', 'this-week-strict', 'this-week', 'next-week', 
  'this-month-strict', 'this-month', 'this-year-strict', 'this-year', 
  'eventually', 'overdue'
];

let bucketLayout;
let essentialView = false;
let essentialViewOff = false;
if (isEnabled('essential-view')) {
  essentialView = true;
}

export function switchEssentialView() {
  essentialViewOff = !essentialViewOff;
}

function prepareBucketHtml() {
  const buckets = pageinit.calcBuckets();
  let container = document.getElementById('bucket-rows');
  let bucketContainer;
  container.innerHTML = '';
  const tabOrMob = isTablet();
  if (tabOrMob) {
    const mainRow = row();
    container.appendChild(mainRow);
    container = mainRow;
  }
  bucketLayout.forEach(bucketLine => {
    if (tabOrMob) {
      bucketContainer = col();
    } else {
      bucketContainer = row();
      bucketContainer.classList.add('row-' + bucketLine.length);
    }
    container.appendChild(bucketContainer);
    bucketLine.forEach(bucketId => {
      const bucket = buckets[bucketId];
      const bucketDiv = bucketHtml(bucket);
      if (tabOrMob) {
        bucketDiv.classList.remove('col');
        bucketDiv.classList.add('in-tab-col');
      }
      bucketContainer.appendChild(bucketDiv);
    });
  });
}

export function refresh() {
  updateTodos();
  showWeek();
}

function calcBucketsToShow() {
  const buckets = pageinit.calcBuckets();
  const idsToShow = new Set();
  bucketLayout.forEach(bucketsLine => {
    bucketsLine.forEach(bucketId => {
      idsToShow.add(bucketId);
    });
  });
  const result = [];
  for (const id of bucketsCalcOrder) {
    if (idsToShow.has(id)) {
      result.push(buckets[id]);
    }
  }
  return result;
}

function copyLayout(src) {
  const dst = [];
  for (let row of src) {
    dst.push(row.slice());
  }
  return dst;
}

export function showWeek(evt) {
  trycatch(() => _showWeek(evt));
}

let swiper;
let showDate;

export function checkNewDayRefresh() {
  if (showDate !== fmtDate(new Date())) {
    console.log("Need to refresh on a new day.");
    showWeek();
  }
}
function _showWeek(evt) {
  showDate = fmtDate(new Date());
  titleRemoveImplicitAttention();
  const prevEssentialView = essentialView;
  const thisWeek = isThisWeek(current);
  const bodyClassList = document.querySelector('body').classList;
  bodyClassList.remove('this-week', 'past', 'future');
  if (thisWeek) {
    bodyClassList.add('this-week');
  } else if (start < new Date()) {
    bodyClassList.add('past');
  } else {
    bodyClassList.add('future');
  }
  if (!essentialViewOff && isEnabled('essential-view') && thisWeek) {
    essentialView = true;
    bodyClassList.add('essential-view');
  } else {
    essentialView = false;
    bodyClassList.remove('essential-view');
  }
  let switchView = essentialView !== prevEssentialView;
  
  if (swiper) {
    swiper.destroy();
    swiper = undefined;
  }
  allPopovers().popover('hide');

  const now = new Date();
  const bodyClasses = document.body.classList;
  if (isMobile()) {
    bucketLayout = copyLayout(mobileLayout);
    bodyClasses.add('mobile');
    bodyClasses.remove('desktop', 'tablet');
  } else if (isTablet()) {
    bucketLayout = copyLayout(tabletLayout);
    bodyClasses.add('tablet');
    bodyClasses.remove('mobile', 'desktop');
  } else {
    bodyClasses.add('desktop');
    bodyClasses.remove('mobile', 'tablet');
  }
  if (isTablet()) {
    document.querySelectorAll('.col .card').forEach(card => reshow(card));
    if (thisWeek) {
      let weekday = now.getDay();
      if (weekday === 0) {
        weekday = 7;
      }
      const removed = bucketLayout[0].splice(1, weekday - 1);
      for (let rem of removed) {
        const remEl = document.getElementById(rem);
        if (remEl) {
          hide(remEl);
        }
      }
    }
  } else if (essentialView) {
    bucketLayout = copyLayout(essentialLayout);
  } else {
    bucketLayout = copyLayout(desktopLayout);    
  }
  if (evt && evt.type === 'resize' || switchView) {
    prepareBucketHtml();
  }
  
  const buckets = calcBucketsToShow();
  dragDrop();

  const alreadyShown = new Set();
  let text = current + ' - ' + fmtDate(endOfWeek(new Date(current)));

  setTitleKeepAttention(titleDateWeek(current));
  document.getElementById('cw').innerHTML = cw(current);
  document.getElementById('cw-prev').innerHTML = cw(new Date(new Date(current).getTime() - millisPerWeek));
  document.getElementById('cw-next').innerHTML = cw(new Date(new Date(current).getTime() + millisPerWeek));

  function findBucketForItem(item) {
    for (const bucket of buckets) {
      if (bucket.matches(item, alreadyShown)) {
        if (item.instanceOf) {
          alreadyShown.add(item.instanceOf);
        } else if (item.wasInstanceOf) {
          alreadyShown.add(item.wasInstanceOf);
        } else {
          alreadyShown.add(item.id);
        }
        bucket.addIfNoInstanceYet(item);
        if (!item.repeat) {
          return;
        }
      }
    }
  }

  buckets.forEach(bucket => bucket.clearItems());
  singleTodos().forEach(findBucketForItem);
  repeatedTodos().forEach(findBucketForItem);
  
  const today = fmtDate(new Date());
  let badgeCount = 0;
  const tagMap = new Map();
  buckets.forEach(bucket => {
    const bucketElement = document.getElementById(bucket.target);
    if (bucketElement === null) {
      return;
    }
    bucketElement.dataset.bucketid = bucket.bucketId;
    bucketElement.dataset.date = bucket.startDate;
    bucketElement.dataset.enddate = bucket.endDate;
    bucketElement.dataset.type = bucket.type;
    const bodyElement = bucketElement.querySelector('.card-body');
    let near = false;
    if (bucket.type === 'day') {
      bucket.subtitle = titleDateDay(bucket.startDate, bucket.num);
      bucketElement.querySelector('.title-date').innerText = bucket.subtitle;
      if (isDateElapsed(bucket.startDate)) {
        bucketElement.classList.add('overdue');
        bodyElement.classList.add('text-danger');
        bucketElement.classList.remove('today');
      } else {
        bucketElement.classList.remove('overdue');
        bodyElement.classList.remove('text-danger');
        if (fmtDate(bucket.startDate) === today) {
          bucketElement.classList.add('today');
        } else {
          bucketElement.classList.remove('today');
        }
      }
    } else if (bucket.type === 'week') {
      bucket.subtitle = titleDateWeek(bucket.startDate);
      bucketElement.querySelector('.title-date').innerText = bucket.subtitle;
      near = isDateNear(bucket.startDate, 'week');
    } else if (bucket.type === 'month') {
      bucket.subtitle = titleDateMonth(bucket.startDate);
      bucketElement.querySelector('.title-date').innerText = bucket.subtitle;
      near = isDateNear(bucket.startDate, 'month');
    } else if (bucket.type === 'year') {
      bucket.subtitle = titleDateYear(bucket.startDate);
      bucketElement.querySelector('.title-date').innerText = bucket.subtitle;
      near = isDateNear(bucket.startDate, 'year');
    } else if (bucket.type === 'eventually') {
      bucketElement.querySelector('.title-date').innerText = '';
    }
    if (near) {
      bucketElement.classList.add('neardate');
      bodyElement.classList.add('text-warning');
    } else {
      bucketElement.classList.remove('neardate');
      bodyElement.classList.remove('text-warning');
    }
    const ulElement = bucketElement.querySelector('.bucket-todos');
    ulElement.textContent = '';
    let allDone = true;
    let timeTodo = 0;
    let firstDate = null;
    let firstType = null;
    let countAll = 0;
    let countDone = 0;
    let countMatched = 0;
    let prevInterTitleHtml = null;
    const badgeRelevant = bucketElement.classList.contains('today') || bucket.target === 'overdue';
    const tagCount = new Map();
    bucket.getItemsOrdered().forEach((item, key, map) => {
      const matchTag = matchesSelectedTag(item);
      if (!item.deleted) {
        if (!item.done) {
          countTags(tagCount, item);
          countTodoTags(tagMap, item);
        }
        countAll++;
        if (item.done) {
          countDone++;
        } else if (badgeRelevant) {
          badgeCount++;
        }
        if (matchTag) {
          countMatched++;
          if ((bucket.type !== 'day' || bucket.num > 1) && item.due) {
            const itemDate = fmtDate(item.due.date);
            const itemType = item.due.type;
            if (bucket.num > 1 || bucket.type !== itemType || !bucket.startDate || fmtDate(bucket.startDate) !== itemDate) {
              if (itemDate !== firstDate || itemType !== firstType) {
                firstDate = itemDate;
                firstType = itemType;
                const interTitle = createItemDateTitle(item, bucket.subtitle);
                if (interTitle) {
                  // not the fastest solution, but works
                  const interHtml = interTitle.innerHTML;
                  if (interHtml !== prevInterTitleHtml) {
                    prevInterTitleHtml = interHtml;
                    ulElement.appendChild(interTitle);
                  }
                }
              }
            }
          }
          let div = document.createElement('div');
          if (!matchTag) {
            div.classList.add('d-none');
          }
          div.classList.add('custom-control', 'custom-checkbox', 'todo-container');
          if (item.done) {
            div.classList.add('todo-done');
          }
          div.innerHTML = createItemHtml(item);
          if (item.imported) {
            if (item.imported.kind === 'calendar') {
              div.classList.add(item.imported.type + '-item');
            }
          }
          
          ulElement.appendChild(div);
          if (!item.done) {
            allDone = false;
            timeTodo = timeTodo + durationToMinutes(item);
          }
        }
      }
    });
    let doneString;
    let doneTooltip;
    const doneEl = bucketElement.querySelector('.bucket-done');
    const doneParent = doneEl.closest('.bucket-info');
    // remove tag count badges
    doneParent.querySelectorAll('.bucket-tag-counter').forEach(btn => btn.remove());
    // and add new ones sorted by descending count 
    const sortedTagCount = [...tagCount.entries()].sort((a, b) => b[1] - a[1]);
    sortedTagCount.forEach(info => {
      const tag = info[0];
      const count = info[1];
      const addBtn = tagColorButton(tag, count);
      doneParent.insertBefore(addBtn, null);
    });
    doneEl.disabled = false;
    if (countAll === 0) {
      doneString = '';
      doneTooltip = '';
    } else if (countDone === 0) {
      doneString = countAll;
      doneTooltip = `${countAll} ${translate('overall')}`;
      doneEl.disabled = true;
    } else {
      doneString = `${countDone}/${countAll}`;
      doneTooltip = `${countDone} ${translate('done')} / ${countAll} ${translate('overall')}`;
    }
    doneEl.innerHTML = doneString;
    doneEl.setAttribute('title', doneTooltip);
    let divEnd = document.createElement('div');
    divEnd.classList.add('fill-element');
    ulElement.appendChild(divEnd);
    if (allDone) {
      bucketElement.classList.remove('overdue');
      bodyElement.classList.remove('text-danger');
      bodyElement.classList.add('text-muted');
    } else {
      bodyElement.classList.remove('text-muted');
      if (bucket.target === 'overdue') {
        bucketElement.classList.add('overdue');
        bodyElement.classList.add('text-danger');
      }
    }
    if (bucket.type === 'day') {
      bucketElement.querySelector('.duration').innerHTML = formatDuration(timeTodo);
    }
    applyBucketWatermark();
  });
  let numTags = 5;
  if (isMobile()) {
    numTags = 3;
  }
  document.getElementById('tags-select').innerHTML = mostFrequentTagsButtons(mostFrequentTagsOf(tagMap, numTags));

  if (isTablet() || essentialView) {
    const overdueBucket = document.getElementById('overdue');
    if (overdueBucket) {
      const overdues = overdueBucket.querySelectorAll('.todo-container:not(.todo-done)').length;
      if (overdues === 0) {
        if (essentialView) {
          hide(overdueBucket.closest('.bucket'));
          const container = overdueBucket.closest('.row');
          container.classList.remove('row-5', 'row-6');
          const num = container.querySelectorAll('.bucket:not(.d-none)').length;
          container.classList.add('row-' + num);
        } else {
          hide(overdueBucket);
        }
      }
    } else {
      if (essentialView) {
        reshow(overdueBucket.closest('.bucket'));
      } else {
        reshow(overdueBucket);
      }
    }
  }
  if (thisWeek) {
    setBadge(badgeCount);
  }
  translateUi();
  updateSyncFilename();
  updateMenuCalendars();

  if (isTablet()) {
    swiper = new Swiper('.swiper', {
      initialSlide: 1,
      effect: 'cube',
      enabled: false,
      on: {
        afterInit: function () {setTimeout(initSlideEvent, 500);}
      }
    });
  }
  if (settings.introshown) {
    mainTour();
  }
}

function initSlideEvent() {
  if (swiper) {
    swiper.enable();
    swiper.on('slideNextTransitionEnd', nextWeek);
    swiper.on('slidePrevTransitionEnd', previousWeek);
  }
}

function stopAllSyncs() {
  hideAllNoHideToasts();
  stopUpdateInterval();
}

function restartAllSyncs() {
  startUpdateInterval();
}

try {
  document.getElementById('tio-version').innerText = tioVersion + ' BETA - ' + tioDate;
  
  document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden') {
      stopAllSyncs();
      console.log(`Browser window hidden.`);
    } else {
      console.log(`Browser window visible.`);
      restartAllSyncs();
    }
  });
  
  pageinit.menu();
  pageinit.initSmallStatus();
  pageinit.initOnceAMinuteUpdater();
  initDropbox();
  if (isEnabled('repeated-timers')) {
    startRepeatTimer();
  }

  if (essentialView) {
    bucketLayout = essentialLayout;
  } else if (isMobile()) {
    bucketLayout = mobileLayout;
  } else if (isTablet()) {
    bucketLayout = tabletLayout;
  } else {
    bucketLayout = desktopLayout;
  }

  prepareBucketHtml();

  initKeyboard();
  initAddEditModals();
  initAllEvents();

  googleAuthInit();
  o365AuthInit();

  showWeek();

  initUpdater();

  if (!settings.introshown) {
    pageinit.aboutTioModal();
  }
  syncPeek();
  let syncChecker = setInterval(syncPeek, 5000);
  let purgeChecker = setInterval(purgeTodos, 3600 * 1000);
} catch (e) {
  alert(e);
  console.log(e);
}
