import { showWeek } from "./index";
import { toastError } from "./alerts";
import { fmtDate, fmtTime, millisPerDay, millisPerHour, millisPerMinute } from "./dates";
import { createItem, todoMap } from "./items";

export function updateCalendarEventTodos(typeId, calEvents, calendarId) {
  const todosBySourceId = new Map();
  const todosByCalendar = new Map();
  for (let todo of todoMap.values()) {
    if (isFromSourceCalendar(typeId, todo)) {
      todosBySourceId.set(todo.imported.id, todo);
      if (todo.imported.container === calendarId) {
        todosByCalendar.set(todo.imported.id, todo);
      }
    }
  }
  const diff = {
    new: [],
    delete: [],
    change: [],
    unchange: []
  };
  for (let event of calEvents) {
    if (todosBySourceId.has(event.id)) {
      let todo = todosBySourceId.get(event.id);
      todosByCalendar.delete(todo.imported.id);
      if (eventChanged(todo, event)) {
        diff.change.push(event);
        updateTodoFromEvent(todo, event);
      } else {
        diff.unchange.push(event);
      }
    } else {
      diff.new.push(event);
      addTodoFromEvent(typeId, event, calendarId);
    }
  }
  // the remainder has not been imported from calendar and is not needed any more
  const now = new Date();
  if (todosByCalendar.size > 0) {
    for (let todo of todosByCalendar.values()) {
      // do not touch if done or past
      if (!todo.done && new Date(todo.due.date) > now) {
        diff.delete.push(todo.id);
        todo.deleted = now;
      }
    }
  }
  console.log(`${typeId} ${calendarId}: ${diff.new.length} new events, ${diff.delete.length} deleted events, ${diff.change.length} updated events, ${diff.unchange.length} unchanged events.`);
  showWeek();
}

function isFromSourceCalendar(typeId, todo) {
  return todo.imported && todo.imported.kind === 'calendar' && todo.imported.type === typeId;
}

function eventChanged(todo, event) {
  const due = fmtDate(new Date(event.start));
  const duration = calcTodoDuration(event.duration);
  const time = fmtTime(event.time);
  const equal = todo.original.title === event.title &&
    todo.original.description === event.description &&
    todo.original.duration === duration &&
    todo.original.due.date === due &&
    todo.original.due.time === time;
  return !equal;
}

function calcTodoDuration(millis) {
  if (millis >= millisPerDay) {
    return '';
  } else if (millis >= millisPerHour) {
    return Math.round(millis / millisPerHour) + 'h';
  } else {
    return Math.round(millis / millisPerMinute) + 'm';
  }
}

function updateTodoFromEvent(todo, cal) {
  if (todo.title === todo.original.title) {
    todo.title = cal.title;
    todo.original.title = cal.title;
  }
  todo.original.title = cal.title;
  const due = fmtDate(new Date(cal.start));
  if (todo.due.date === todo.original.due.date) {
    todo.due.date = due;
  }
  const time = fmtTime(cal.time);
  if (todo.due.time === todo.original.due.time) {
    todo.due.time = time;
  }
  todo.original.due.date = due;
  if (todo.notes === todo.original.notes) {
    todo.notes = cal.description;
  }
  todo.original.notes = cal.description;
  const duration = calcTodoDuration(cal.duration);
  if (todo.duration === todo.original.duration) {
    todo.duration = duration;
  }
  todo.original.duration = duration;
}

function addTodoFromEvent(typeId, calEvent, calTitle) {
  const todo = createItem(calEvent.title, true, 'day', fmtDate(new Date(calEvent.start)), fmtTime(calEvent.time), false, false, false, false, calcTodoDuration(calEvent.duration), calEvent.description);
  todo.original = {
    title: todo.title, 
    due: {
      type: 'day',
      date: todo.due.date,
      time: todo.due.time
    },
    duration: todo.duration,
    notes: todo.notes
  };
  todo.imported = {
    kind: 'calendar',
    type: typeId,
    id: calEvent.id,
    container: calTitle
  };
  return todo;
}

export function findSignedOutCalendarItems(type) {
  for (let todo of todoMap.values()) {
    if (isFromCalendar(type, todo)) {
      console.warn('There are items imported from ' + type + ' Calendar, but no valid connection.');
      if (!localStorage.getItem(type + '-calendar-reauthentication-warning')) {
        toastError(`There are imported calendar items, but no active connection!<br>
          Consider re-authenticating your ${type} calendar.`);
        localStorage.setItem(type + 'calendar-reauthentication-warning', new Date());
      }
      return;
    }
  }
}

export function isFromCalendar(type, todo) {
  return todo.imported && todo.imported.kind === 'calendar' && todo.imported.type === type;
}
