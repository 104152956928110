import { saveSettings, settings } from './settings';
import { translate, uiLanguage } from './i18n';
import { closeHamburger } from './events';
import { modal } from './modal';

const allSettings = [
  {
    name: 'behavior',
    settings: [
      { name: 'weekly-overdue',
        description: {
          en: 'In future weeks also show todos after today as overdue.',
          de: 'In zukünftigen Wochen auch kommende Aufgaben als überfällig anzeigen.'
        }
      }
    ],
    bgcolor: 'white'
  },
  {
    name: 'experimental',
    settings: [
      { name: 'essential-view',
        description: {
          en: 'Reduce cards in current week default display.',
          de: 'In der aktuellen Woche nur die wichtigsten Karten anzeigen.'
        }
      },
      {
        name: 'google-calendar',
        description: {
          en: 'Add Google Calendar events as todo items.',
          de: 'Google Kalender Termine als Todo-Einträge übernehmen.'
        }
      },
      {
        name: 'o365-calendar',
        description: {
          en: 'Add O365 Calendar events as todo items.',
          de: 'O365 Kalender Termine als Todo-Einträge übernehmen.'
        }
      },
      {
        name: 'repeated-timers',
        description: {
          en: 'Show configured, repeated alarms after a timer has run out.',
          de: 'Konfigurierte, wiederholte Alarme nach bestimmten Zeiträumen anzeigen.'
        }
      },
      {
        name: 'auto-tags',
        description: {
          en: 'Automatically assign tags based on todo properties.',
          de: 'Automatisch Tags anhand von Todo-Eigenschaften zuordnen.'
        }
      }
    ],
    bgcolor: '#f0e840'
  },
  {
    name: 'debug',
    settings: [
      { name: 'calendar-delete-button',
        description: {
          en: 'Add menu item to delete all imported calendar entries.',
          de: 'Neuer Menüeintrag zum Löschen aller importierten Kalender-Aufgaben.'
        }},
      { name: 'delete-all',
        description: {
          en: 'Add menu item to delete <em><b>ALL</b></em> todos. Use carefully!',
          de: 'Neuer Menüeintrag zum Löschen <em><b>ALLER</b></em> Aufgaben. Nur mit Vorsicht benutzen!'
       } },
      { name: 'debug-properties',
        description: {
          en: 'Show all todo properties in details dialog.',
          de: 'Alle Eigenschaften einer Aufgabe im Details-Dialog anzeigen.'
      }},
      { name: 'debug-prio',
      description: {
        en: 'Store the priority trend of each todo item and show in details dialog.',
        de: 'Die Entwicklung der Priorität jeder Aufgabe speichern und im Details-Dialog anzeigen.' 
      }},
      { name: 'mobile-console',
      description: {
        en: 'Load remote JavaScript to enable a mobile console.',
        de: 'Externes JavaScript laden, um eine mobile Konsole zu verwenden.' 
      }},
    ],
    bgcolor: '#f86373'
  },
];

const defaultEnabled = [
];

function ensureEnabledfeatures() {
  if (!settings.hasOwnProperty('enabledfeatures')) {
    settings.enabledfeatures = [...defaultEnabled];
  }
  return settings.enabledfeatures;
}

export function isEnabled(id) {
  const enabled = ensureEnabledfeatures();
  if (enabled && enabled.includes(id)) {
    return true;
  }
  return false;
}

export function enableFeatures(features) {
  const enabled = ensureEnabledfeatures();
  features.forEach(ft => {
    if (!enabled.includes(ft)) {
      enabled.push(ft);
    }
  });
  saveSettings();
}

export function disableFeatures(features) {
  const enabled = ensureEnabledfeatures();
  features.forEach(ft => {
    for (let i = 0; i < enabled.length; i++) {                   
      if (enabled[i] === ft) { 
        enabled.splice(i, 1); 
        i--; 
      }
    }
  });
  saveSettings();
}

export function configureSettingsAndFeatures() {
  closeHamburger();
  let list = '';
  allSettings.forEach(settings => {
    const area = settings.name;
    const color = settings.bgcolor;
    list = list + `<h6>${translate('settingsarea-' + area)}</h6>`;
    settings.settings.forEach(setting => {
      const name = setting.name;
      let description = '';
      if (setting.description) {
        if (setting.description[uiLanguage]) {
          description = `<br><small>${setting.description[uiLanguage]}</small>`;
        } else if (setting.description.en) {
          description = `<br><small>${setting.description.en}</small>`;
        }
      }
      const enabled = isEnabled(name) ? ' checked="checked"' : '';
      list = list + `<li class="list-group-item" style="background-color: ${color};"><div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="choose-setting-${name}" data-setting="${name}" ${enabled}>
        <label class="custom-control-label" for="choose-setting-${name}">${translate('setting-' + name)}</label>
        ${description}
      </div></li>`;
    });  
  });
  const dm = modal('preferences', 'Preferences', 
  `<p><span>Enable or disable settings:</span></p>
  <div class="form-group">
  <ul class="list-group">
    ${list}
  </ul>
  </div>`, 'Accept', evt => updateFeatures(dm)).get(0);
}

function updateFeatures(modal) {
  const settings = modal.querySelectorAll('input[type="checkbox"]');
  const toEnable = [];
  const toDisable = [];
  settings.forEach(s => {
    const id = s.dataset.setting;
    if (s.checked) {
      toEnable.push(id); 
    } else {
      toDisable.push(id);
    }
    enableFeatures(toEnable);
    disableFeatures(toDisable);
  });
  window.location.reload();
}