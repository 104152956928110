import jquery from 'jquery';
import { translateUi } from './i18n';

/**
 * Prepares a modal with submit button and optional cancel button.
 * A self-destroying event listener invoking submitAction is registered.
 * submitAction receives the modal DOM element as parameter.
 */
export function modal(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo) {
  let cancelHtml = '';
  if (cancel) {
    cancelHtml = `<button type="button" class="btn btn-secondary modal-cancel" id="${name}-modal-cancel" data-dismiss="modal">${cancel}</button>`;
  }
  let todoHtml = '';
  if (referringTodo) {
    todoHtml = `data-todo="${referringTodo.id}"`;
  }
  const $modal = jquery(`<div class="modal fade" id="${name}-modal" ${todoHtml} tabindex="-1" aria-labelledby="${name}-modal-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="${name}-modal-label">${title}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ${content}
      </div>
      <div class="modal-footer">
        ${cancelHtml}
        <button type="button" class="btn btn-primary modal-submit" id="${name}-modal-submit">${submitTitle}</button>
      </div>
    </div>
  </div>
</div>
`);
  jquery("body").append($modal);
  translateUi();
  const submitButton = document.getElementById(name + '-modal-submit');
  selfDestroyingModalAction(submitButton, function () {
    const keepOpen = submitAction(document.getElementById(name + '-modal'));
    if (!keepOpen) {
      destroyModal($modal);
    }
  });
  if (cancelAction) {
    const cancelButton = document.getElementById(name + '-modal-cancel');
    selfDestroyingModalAction(cancelButton, evt => {
      const keepOpen = cancelAction(evt);
      if (!keepOpen) {
        destroyModal($modal);
      }
    });
  }
  $modal.modal('show');
  return $modal;
}

function selfDestroyingModalAction(button, action) {
  const fullAction = function () {
    button.removeEventListener('click', fullAction);
    action();
  };
  button.addEventListener('click', fullAction);
}
function destroyModal($modal) {
  $modal.modal('hide');
  $modal.on('hidden.bs.modal', function () {
    $modal.modal('dispose');
    $modal.data('bs.modal', null);
    $modal.remove();
  });
}

let loadingModalShown = false;
export function loadingModalShow(text) {
  if (loadingModalShown) {
    console.log('loadingModalShow(): Loading modal already shown, doing nothing.');
  }  
  const modal = document.createElement('div');
  modal.id = 'loadingModal';
  modal.classList.add('modal', 'fade');
  modal.dataset.backdrop = 'static';
  modal.dataset.keyboard = 'false';
  modal.tabindex = -1;
  modal.setAttribute('aria-labelledby', 'loading-modal-label');
  modal.setAttribute('aria-hidden', 'true');
  const content = `<div class="modal-dialog full_modal-dialog">
    <div class="modal-content full_modal-content my-5">
      <div class="modal-body text-center my-5">
        <div class="i18n-sync-data" id="loading-modal-label">${text}</div>
        <div class="spinner-grow text-secondary mt-3" style="width: 4rem; height: 4rem;" role="status">
          <span class="sr-only i18n-loading">Loading...</span>
        </div>
      </div>
    </div>
  </div>`;
  modal.innerHTML = content;
  document.body.appendChild(modal);
  jquery('#loadingModal').modal('show');
  loadingModalShown = true;
}

export function loadingModalHide() {
  loadingModalShown = false;
  destroyModal(jquery('#loadingModal'));
}

// called on focus as the modal maybe still visible
export function checkLoadingModalHide() {
  if (loadingModalHide && jquery('#loadingModal').length > 0) {
    console.log("Loading modal accidentally not closed, trying again.");
    loadingModalHide();
  }
}