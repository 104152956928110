import jquery from 'jquery';
import { listTodos } from './items';
import { markdown, iconImported, iconRepeat, iconInstance, iconSingle } from './html';
import { fmtDate, fmtDay, fmtDayShort, infoDateDay, titleDateDay, titleDateMonth, titleDateWeek } from './dates';
import { focusInput } from './add-edit-modals';

// will be purged on
// next instance on
// clickable -> edit, etc.
export function openSearchModal() {
    jquery('#searchModal').modal('show');
    updateSearchData();
    jquery('#searchModal').on('shown.bs.modal', function () {
        const input = document.getElementById('search-input');
        input.value = '';
        focusInput(input);
    });
}

function checked(name) {
    return document.getElementById('search-check-' + name).checked;
}

export function updateSearchData() {
    const contains = document.getElementById('search-input').value;
    const repeated = checked('repeated');
    const single = checked('single');
    const instance = checked('instance');
    const deleted = checked('deleted');
    const done = checked('done');
    const imported = checked('imported');
    const type = [];
    if (repeated) {
        type.push('repeated');
    }
    if (single) {
        type.push('single');
    }
    if (instance) {
        type.push('instance');
    }

    const div = document.getElementById('search-results');
    div.innerHTML = '';
    const items = listTodos({
        contains: contains,
        type: type,
        deleted: deleted,
        imported: imported,
        done: done
    });
    document.getElementById('search-header').innerHTML = `<b>${items.length} hits</b>:`;

    sortByTitleAndDate(items);
    items.forEach(item => {
        div.appendChild(itemHtml(item, contains));
    });

}

function sortByTitleAndDate(items) {
    items.sort(function (el1, el2) {
        return todoTitle(el1).localeCompare(todoTitle(el2)) ||
          todoDue(el1).localeCompare(todoDue(el2));
    });
}

export function todoTitle(el) {
    const div = document.createElement('div');
    div.innerHTML = markdown(el.title);
    return div.innerText;
}

function todoDue(el) {
    if (!el.due || !el.due.date) {
        return "";
    }
    return fmtDate(new Date(el.due.date));
}

function itemHtml(item, highlightText) {
    const title = highlight(todoTitle(item), highlightText);
    const duration = item.duration ? item.duration + ',' : '';
    let repeat = '';
    if (item.repeat) {
        const every = item.repeat.every === 1 ? '' : item.repeat.every;
        const scope = item.repeat.every > 1 ? item.repeat.scope + 's' : item.repeat.scope;
        repeat = `every ${every} ${scope}`;
    }
    let due = '';
    if (item.due) {
        let date;
        if (item.due.type === 'month') {
            date = titleDateMonth(item.due.date);
        } else if (item.due.type === 'week') {
            date = titleDateWeek(item.due.date);
        } else {
            date = fmtDayShort(item.due.date) + ', ' + infoDateDay(item.due.date, 1);
        }
        if (item.repeat) {
            due = 'starting ';
        }
        due = due + date;
    } else {
        due = 'eventually';
    }
    if (due !== '') {
        due = `(${due})`;
    }
    let html = title;
    if (item.deleted) {
        html = `<del class="text-danger">${html}</del> <small>deleted at ${fmtDate(item.deleted)}</small>`;
    } else if (item.done) {
        html = `<del>${html}</del> <small>done</small>`;
    }
    html += ` <small>${duration} ${repeat} ${due}</small>`;
    if (item.imported) {
        html = html + ` <small>imported from ${item.imported.kind}/${item.imported.type}/${item.imported.container}</small>`;
    }
    if (item.imported) {
        html = iconImported + ' ' + html;
    } else if (item.repeat) {
        html = iconRepeat + ' ' + html;
    } else if (item.instanceOf) {
        html = iconInstance + ' ' + html;
    } else {
        html = iconSingle + ' ' + html;
    }

    const div = document.createElement('div');
    div.classList.add('search-todo');
    div.id = item.id;
    div.innerHTML = html;
    return div;
}

function highlight(text, toHighlight) {
    if (!toHighlight) {
        return text;
    }
    const lowerText = text.toLowerCase();
    const lowerHighlight = toHighlight.toLowerCase();
    const len = toHighlight.length;
    let pos = lowerText.lastIndexOf(lowerHighlight);
    while (pos >= 0) {
        text = text.substring(0, pos) + '<mark>' + text.substring(pos, pos+len) + '</mark>' + text.substring(pos + len);
        if (pos === 0) {
            pos = -1;
        } else {
            pos = lowerText.lastIndexOf(lowerHighlight, pos - 1);
        }
    }
    return text;
}