export const pageinit_fr = `<div class="jumbotron">
    <p class="lead"><b>TIO est un planificateur de tâches hebdomadaire, d'abord hors ligne</b></p>
    <ul class="list-unstyled">
    <li>Toutes les tâches de la semaine en un coup d'œil.</li>
    <li>Gardez un œil sur les tâches en retard.</li>
    <li>Toutes les données restent dans votre navigateur.</li>
    <li>Formatez votre texte avec Markdown.</li>
    <li><em>Optionnel</em> : Synchronisez les tâches avec votre cloud.</li>
    <li><em>Optionnel</em> : Intégrez des événements de calendrier (Google ou Microsoft Outlook).</li>
    </ul></div>
    <div class="jumbotron" id="cookie-banner">
Ce site Web stocke les données de l'application dans le stockage local de votre navigateur. 
En utilisant des services tiers tels que Google Calendar, O365 ou Dropbox, des cookies techniquement nécessaires peuvent être définis. 
Pour plus d'informations, consultez notre [politique de confidentialité].<br><br>
    Si vous ne le souhaitez pas, veuillez ne pas utiliser ce site Web. 
    </div><hr>
    <div class="jumbotron tourinfo">Si vous le souhaitez, nous vous expliquerons les fonctionnalités de TIO lorsqu'elles apparaîtront pour la première fois à l'écran.</div>
`;

export function mainTour_fr() {
return [
        ['body.desktop #cw', 'TIO affiche vos tâches sur une base hebdomadaire. Ici sont affichées la semaine calendaire actuelle et la date exacte.<br><br>' +
        'Vous pouvez naviguer dans cette visite avec Suivant/Précédent ou avec les flèches droite/gauche.<br><br>' +
        'Pour fermer cette visite, cliquez simplement n\'importe où en dehors de cette notification', '0.6.2'],
        ['body:not(.desktop) #cw', 'TIO affiche vos tâches sur une base hebdomadaire. Ici sont affichées la semaine calendaire actuelle et la date exacte.<br><br>' +
        'Changez la semaine affichée en balayant l\'écran avec votre doigt vers la gauche ou la droite.<br><br>' +
        'Vous pouvez naviguer dans cette visite avec Suivant/Précédent.<br><br>' +
        'Pour fermer cette visite, appuyez simplement n\'importe où en dehors de cette notification', '0.6.2'],
        ['body:not(.mobile) #week-left', 'Cliquez ici pour afficher la semaine précédente.'],
        ['body:not(.mobile) #week-today', 'Cliquez ici pour afficher la semaine actuelle.'],
        ['body.desktop.essential-view #week-today', 'Vous voyez actuellement la vue principale de la semaine en cours. Cliquez ici pour passer à la vue complète.<br><br>Cliquez à nouveau pour revenir à la vue principale.'],
        ['body:not(.mobile) #week-right', 'Cliquez ici pour afficher la semaine suivante.'],
        ['body:not(.mobile) #timerMenuButton', 'Ici, vous pouvez démarrer un simple minuteur. Une alarme retentira à la fin.'],
        ['#hamburger', 'Voici un menu avec des fonctions avancées.<br><br>' +
        'Le bouton affiche également l\'état de la synchronisation si vous avez, par exemple, lié TIO à votre Dropbox:' +
        '<ul><li>bleu : Tout est synchronisé.<li>orange : Le dernier état doit encore être enregistré.<li>rouge : Une erreur s\'est produite.</ul>'],
        ['body.desktop .card:not(.overdue)', '<p>Toutes les tâches dans TIO sont triées dans des cartes. Une carte peut représenter un jour, une semaine ou un mois.</p>' +
        '<p>Cliquez dans la zone vide d\'une carte pour créer une nouvelle tâche.</p>' +
        '<p>Déplacez les tâches par glisser-déposer à l\'intérieur d\'une carte et entre les cartes.</p>'],
        ['body:not(.desktop) .bucket:not(.d-none) .card:not(.overdue)', '<p>Toutes les tâches dans TIO sont triées dans des cartes. Une carte peut représenter un jour, une semaine ou un mois.</p>' +
        '<p>Appuyez dans la zone vide d\'une carte pour créer une nouvelle tâche.</p>' +
        '<p>Le glisser-déposer pour les tâches ne fonctionne pas encore sur les téléphones ou tablettes.</p>'],
        ['.bucket:not(.d-none) .card:not(.overdue) .card-title .title', 'En haut d\'une carte se trouve son nom.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .title-date', '...et en dessous, sa date ou sa plage de dates.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done)', '<p>Toutes les tâches d\'une carte sont listées avec leur titre à l\'intérieur de la carte.</p>' +
        '<p>Cliquez sur le titre de la tâche pour voir plus de détails.</p>' +
        '<p>Un double-clic sur le titre ouvre la boîte de dialogue d\'édition.</p>'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) input[type="checkbox"]', 'Dans la zone de gauche se trouve une case à cocher.<br>Cliquez dessus pour cocher une tâche.<br>Elle sera alors masquée.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .card-title .duration.badge', 'La durée totale des tâches d\'une carte (si spécifiée) est affichée ici.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .bucket-done:not(:empty)', '<p>Ici est indiqué le nombre de tâches que contient la carte et combien d\'entre elles sont déjà terminées.</p>' +
        '<p>Cliquez dessus pour afficher ou masquer les tâches terminées.</p>'],
        ['.bucket:not(.d-none) #overdue:not(.d-none)', 'Toutes les tâches qui auraient dû être terminées avant la semaine actuelle sont listées dans la carte <span style="color:red; font-weight: bold;">En retard</span>.'],
        ['.bucket:not(.d-none) #eventually:not(.d-none)', 'Toutes les tâches sans date sont listées dans la carte <span style="color:red; font-weight: bold;">Un jour</span>.'],
        ['.bucket:not(.d-none) .card-body.nothing-to-do', 'Les cartes sans tâches ont un soleil en filigrane.'],
        ['.bucket:not(.d-none) .card-body.all-done', 'Les cartes où toutes les tâches sont terminées ont une coche de validation en filigrane.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .due-time', 'Si une tâche doit être terminée à une heure précise, elle est affichée ici.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .duration', 'La durée d\'une tâche est affichée ici.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .bi-sticky', 'Les tâches avec ce symbole contiennent des notes.'],
];
}

export function addModalTour_fr() {
return [
        ['#addTodoModal .modal-content', 'La boîte de dialogue pour ajouter de nouvelles tâches. Un titre doit être saisi, le reste est facultatif.<br><br>' +
        'Vous devriez cependant définir une date d\'échéance.<br><br>' +
        'Et une durée est toujours utile. Ainsi, vous saurez à l\'avance si votre journée est trop chargée.'],
        ['#addTodoTitle', 'Le titre de la tâche, qui sera affiché dans la vue hebdomadaire.<br><br>' +
        'Vous pouvez utiliser Markdown (si vous ne le connaissez pas, consultez par exemple <a href="https://fr.wikipedia.org/wiki/Markdown#Exemples_de_syntaxe" target="_blank">Wikipedia</a>) ' +
        'pour écrire du texte en <code>*<em>italique</em>*</code>, <code>**<b>gras</b>**</code> ou <code>***<b><em>gras italique</em></b>***</code>.<br><br>' +
        'Vous pouvez également ajouter un <code>[lien](https://tio.one)</code>.'],
        ['#addTodoDuration', 'Durée optionnelle de la tâche, par exemple <code>10m</code> (dix minutes) ou <code>1h</code> (une heure).'],
        ['#addTodoModal .date-time-input', 'Quand la tâche doit-elle être terminée ? Cela peut être un jour, une semaine, un mois ou une année.<br><br>La date choisie est le jour ou un jour de la semaine, etc.'],
        ['#addTodoModal .repeat-input', 'Définir une répétition optionnelle de la tâche. Cochez la case à gauche et sélectionnez un intervalle, par exemple chaque jour ouvrable ("tous les jours ouvrables") ou toutes les deux semaines.'],
        ['#addTodoRepeatPrio', 'Une priorité optionnelle de la tâche (ou de la série de tâches). Utilisée pour le premier tri.<br><br>' +
        'Vous pouvez utiliser n\'importe quel nombre entier. Les tâches sont listées par ordre croissant de priorité.'],
        ['#addTodoNotes', 'Notes diverses sur la tâche. Vous pouvez également utiliser Markdown ici. Par exemple, des listes avec <code>*</code>.'],
        ['#addTodoModal #resetTodo', 'Réinitialiser toutes les entrées de cette boîte de dialogue à leur état initial.'],
        ['#addTodoModal .modal-footer .i18n-close', 'Fermer la boîte de dialogue. Toutes les entrées seront annulées.'],
        ['#addTodoSubmit', 'Enregistrer la nouvelle tâche. '],
];
}

export function editModalTour_fr() {
return [
        ['#editTodoModal .modal-content', 'La boîte de dialogue de modification. Fonctionne de la même manière que la boîte de dialogue pour les nouvelles tâches.<br><br>' +
        'Seuls les boutons d\'action diffèrent.<br><br>' +
        'Attention : Si la tâche fait partie d\'une série, vous modifiez d\'abord uniquement la tâche individuelle. Pour modifier toute la série, allez sur "Modifier la série".'],
        ['#deleteTodo.i18n-delete', 'Supprimer cette tâche.'],
        ['#deleteTodoSeries.visible', 'Supprimer une série de tâches. Vous pouvez choisir de supprimer toutes les répétitions ou seulement la répétition sélectionnée et toutes les suivantes.'],
        ['#editTodoSeries.visible', 'Ouvrir une nouvelle boîte de dialogue pour modifier toute la série.'],
        ['#editTodoModal .modal-footer .i18n-close', 'Fermer la boîte de dialogue de modification, toutes les modifications seront annulées.'],
        ['#editTodoSubmit', 'Enregistrer les modifications.'],
];
}

export function menuTour_fr() {
return [
        ['#hamburger-menu', 'Ceci est le menu de TIO. Vous y trouverez certaines fonctions qui ne sont pas souvent nécessaires.'],
        ['#tio-version', 'La version actuelle de TIO.<br><br>Actuellement, TIO est en phase bêta et certaines choses ne fonctionnent pas encore correctement.'],
        ['#add-todo-button', 'Ajouter une nouvelle tâche pour aujourd\'hui. A la même fonction qu\'un clic dans la carte d\'aujourd\'hui.<br><br>Alternativement, appuyez sur la touche <b>+</b>.'],
        ['#sync-save-button', 'Enregistrer les tâches maintenant.<br><br>Normalement, vous n\'avez pas besoin de le faire, car TIO enregistre automatiquement tout localement.<br><br>Si vous liez TIO à votre Dropbox, l\'état actuel y sera également enregistré régulièrement. Si cela ne fonctionne pas, vous pouvez réessayer ici.'],
        ['#delete-all', 'Supprimer toutes les tâches (vraiment toutes!).<br><br>Faites cela uniquement si vous savez exactement ce que vous faites.'],
        ['#preferences', 'Ici, vous pouvez faire divers réglages ou activer/désactiver des fonctions expérimentales.'],
        ['body:not(.dropbox-connected) #dropbox-login-button', 'Lier TIO à votre Dropbox - comme sauvegarde et pour la synchronisation entre plusieurs appareils.'],
        ['body.dropbox-connected #dropbox-logout-button', 'Dissocier Dropbox.'],
        ['body.dropbox-connected #dropbox-file', 'Sélectionner le fichier Dropbox dans lequel toutes les données TIO seront enregistrées.'],
        ['body:not(.google-connected) #google-login-button', 'Lier TIO à votre compte Google et importer vos événements de calendrier dans TIO.<br><br>' +
        'C\'est une synchronisation unidirectionnelle du calendrier vers TIO. Les modifications dans le calendrier seront importées dans TIO. Si vous modifiez un événement dans TIO, cela restera dans TIO.<br><br>' +
        'Après avoir cliqué sur "Connecter", la boîte de dialogue de connexion Google s\'ouvrira immédiatement.'
        ],
        ['body.google-connected #google-logout-button', 'Dissocier Google.'],
        ['body.google-connected #google-calendar-list', 'Sélectionner le calendrier à intégrer.'],
        ['#about-tio', 'La boîte de dialogue d\'information du démarrage. Vous pouvez également réactiver la visite ici.'],
];
}

export function miniSyncTour_fr() {
return [
        ['#small-status', 'Lorsque le bouton d\'état défile hors de l\'écran, cette petite version apparaît à la place.'],
];
}

export function todoDetailsTour_fr() {
return [
        ['.todo-popover .popover-header', 'Voici les détails d\'une tâche, le titre est affiché en haut.'],
        ['.todo-popover .popover-body', 'Ici sont affichées toutes les notes et autres informations d\'une tâche.'],
        ['.todo-popover .popover-header .bi-arrow-repeat', 'Une tâche récurrente est marquée par une flèche ronde en haut.'],
        ['.todo-popover .popover-header', 'Voici les détails d\'une tâche, le titre est affiché en haut.'],
        ['.todo-popover .popover-body .created-title', 'Quand cette tâche a-t-elle été créée ?'],
        ['.todo-popover .popover-body .repeat-title', 'Selon quel schéma une tâche est-elle répétée ?'],
        ['.todo-popover .popover-body .original-created-title', 'Quand la série a-t-elle été créée ?'],
        ['.todo-popover .popover-footer .dropdown-toggle', 'Il y a plusieurs boutons d\'action pour une tâche. Cette flèche à côté déploie d\'autres actions similaires.'],
        ['.todo-popover .popover-footer .to-top', 'Déplacer la tâche à l\'intérieur de sa carte.'],
        ['.todo-popover .popover-footer .btn-group>.plus-1w', 'Déplacer la tâche à la semaine suivante.'],
        ['.todo-popover .popover-footer .btn-group>.plus-1d', 'Déplacer la tâche au jour suivant.'],
        ['.todo-popover .popover-footer .to-0', 'Planifier la tâche pour aujourd\'hui.'],
        ['.todo-popover .popover-footer .clone', 'Créer une copie exacte de cette tâche.'],
        ['.todo-popover .popover-footer .edit', 'Modifier cette tâche. Pour les tâches récurrentes, il y a également la possibilité de modifier la série.'],
        ['.todo-popover .popover-footer .delete', 'Supprimer cette tâche (individuelle).'],
];
}