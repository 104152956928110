import jquery from 'jquery';

export function allPopovers() {
    return jquery('label[data-content!=""]');
}

export function popovers(div) {
    return jquery(div).find('label[data-content!=""]');
}

const checkIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>`;
const checkIcon2 = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ddd" class="bi bi-check-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg>`;
const sunIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ddd" class="bi bi-sun" viewBox="0 0 16 16">
<path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
</svg>`;
const base64Check = btoa(checkIcon2);
const base64Sun = btoa(sunIcon);
export function applyBucketWatermark() {
  document.querySelectorAll('.bucket-todos').forEach(bucket => {
    const notDoneCount = bucket.querySelectorAll('.todo-container:not(.todo-done)').length;
    const doneCount = bucket.querySelectorAll('.todo-container.todo-done').length;
    const cardClasses = bucket.closest('.card-body').classList;
    if (notDoneCount === 0 && doneCount > 0) {
      bucket.style.backgroundImage = `url("data:image/svg+xml;base64,${base64Check}")`;
      cardClasses.add('all-done');
    } else if (notDoneCount === 0 && doneCount === 0) {
      bucket.style.backgroundImage = `url("data:image/svg+xml;base64,${base64Sun}")`;
      cardClasses.add('nothing-to-do');
    } else {
      bucket.style.backgroundImage = '';
      cardClasses.remove('all-done', 'nothing-to-do');
    }
  });
}

export function hide(el) {
  const sup = el.closest('.in-tab-col');
  if (sup) {
    sup.classList.add('d-none');
  } else {
    el.classList.add('d-none');
  }
}

export function reshow(el) {
  if (el !== null) {
    const sup = el.closest('.in-tab-col');
    if (sup) {
      sup.classList.remove('d-none');
    } else {
      el.classList.remove('d-none');
    }
  }
}
  
  