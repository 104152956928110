export const pageinit_en = `<div class="jumbotron">
    <p class="lead"><b>TIO is a weekly task planner, offline first</b></p>
    <ul class="list-unstyled">
    <li>All tasks of a week at a glance.</li>
    <li>Keep an eye on overdue tasks.</li>
    <li>All data stays in your browser.</li>
    <li>Format your text with Markdown.</li>
    <li><em>Optional</em>: Sync tasks to your cloud.</li>
    <li><em>Optional</em>: Integrate (Google or Microsoft Outlook) calendar events.</li>
    </ul></div>
    <div class="jumbotron" id="cookie-banner">
This website stores application data in your browser's local storage. 
By using third-party services like Google Calendar, O365, or Dropbox, technically necessary cookies may be set. 
For more information, see our [Privacy Policy].<br><br>
    If you do not agree, please do not use this website. 
    </div><hr>
    <div class="jumbotron tourinfo">If you like, we will explain TIO's features as they first appear on screen.</div>
`;

export function mainTour_en() {
return [
        ['body.desktop #cw', 'TIO displays your tasks on a weekly basis. Here you can see the currently displayed calendar week and the exact date.<br><br>' +
        'You can navigate through this tour with Next/Back or with the right/left arrow keys.<br><br>' +
        'To close this tour, simply click anywhere outside this hint', '0.6.2'],
        ['body:not(.desktop) #cw', 'TIO displays your tasks on a weekly basis. Here you can see the currently displayed calendar week and the exact date.<br><br>' +
        'Change the displayed week by swiping the screen content left or right with your finger.<br><br>' +
        'You can navigate through this tour with Next/Back.<br><br>' +
        'To close this tour, simply tap anywhere outside this hint', '0.6.2'],
        ['body:not(.mobile) #week-left', 'Click here to display the previous week.'],
        ['body:not(.mobile) #week-today', 'Click here to display the current week.'],
        ['body.desktop.essential-view #week-today', 'You are currently viewing the main overview of the current week. Click here to switch to the full view.<br><br>Click again to return to the main view.'],
        ['body:not(.mobile) #week-right', 'Click here to display the next week.'],
        ['body:not(.mobile) #timerMenuButton', 'Here you can start a simple timer. An alarm will sound when it expires.'],
        ['#hamburger', 'Here is a menu with advanced features.<br><br>' +
        'The button also shows the sync status if you have linked TIO with your Dropbox, for example:' +
        '<ul><li>blue: Everything is synced.<li>orange: The last state still needs to be saved.<li>red: There was an error.</ul>'],
        ['body.desktop .card:not(.overdue)', '<p>All tasks in TIO are sorted into cards. A card can represent a day, a week, or a month.</p>' +
        '<p>Click in the empty area of a card to create a new task.</p>' +
        '<p>Move tasks by dragging and dropping within a card and between cards.</p>'],
        ['body:not(.desktop) .bucket:not(.d-none) .card:not(.overdue)', '<p>All tasks in TIO are sorted into cards. A card can represent a day, a week, or a month.</p>' +
        '<p>Tap in the empty area of a card to create a new task.</p>' +
        '<p>Drag and drop for tasks currently does not work on mobile or tablet.</p>'],
        ['.bucket:not(.d-none) .card:not(.overdue) .card-title .title', 'At the top of a card is its name.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .title-date', '...and below that is its date or date range.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done)', '<p>All tasks of a card are listed with their title within the card.</p>' +
        '<p>Click on the task title to see more details.</p>' +
        '<p>Double-clicking the title opens the edit dialog.</p>'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) input[type="checkbox"]', 'On the left side is a checkbox.<br>Click it to check off a task.<br>It will then be hidden.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .card-title .duration.badge', 'The total duration of the tasks of a card (if specified) is shown here.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .bucket-done:not(:empty)', '<p>Here it shows how many tasks the card contains and how many of them are already done.</p>' +
        '<p>Click it to show or hide the completed tasks.</p>'],
        ['.bucket:not(.d-none) #overdue:not(.d-none)', 'All tasks that should have been completed before the current week are listed in the <span style="color:red; font-weight: bold;">Overdue</span> card.'],
        ['.bucket:not(.d-none) #eventually:not(.d-none)', 'All tasks without a date are listed in the <span style="color:red; font-weight: bold;">Eventually</span> card.'],
        ['.bucket:not(.d-none) .card-body.nothing-to-do', 'Cards without tasks have a sun as a watermark.'],
        ['.bucket:not(.d-none) .card-body.all-done', 'Cards in which all tasks are completed have a completed checkmark as a watermark.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .due-time', 'If a task is due at a specific time, it is displayed here.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .duration', 'The duration of a task is displayed here.'],
        ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .bi-sticky', 'Tasks with this icon contain notes.'],
];
}

export function addModalTour_en() {
return [
        ['#addTodoModal .modal-content', 'The input dialog for new tasks. A title must be entered, the rest is optional.<br><br>' +
        'However, you should definitely set a due date.<br><br>' +
        'And a duration is always useful. This way you will notice early enough if your day is too full.'],
        ['#addTodoTitle', 'The title of the task, which will then be displayed in the weekly overview.<br><br>' +
        'You can use Markdown (if you are not familiar with it, check out <a href="https://en.wikipedia.org/wiki/Markdown#Examples" target="_blank">Wikipedia</a> for example) ' +
        'to write text in <code>*<em>italic</em>*</code>, <code>**<b>bold</b>**</code> or <code>***<b><em>bold italic</em></b>***</code>.<br><br>' +
        'You can also set a <code>[link](https://tio.one)</code>.'],
        ['#addTodoDuration', 'Optional duration of the task, e.g. <code>10m</code> (ten minutes) or <code>1h</code> (one hour).'],
        ['#addTodoModal .date-time-input', 'When is the task due? It can be a day, week, month, or year.<br><br>The selected date is the day or a day in the week, etc.'],
        ['#addTodoModal .repeat-input', 'Optionally set a repetition for the task. To do this, check the box on the left and select an interval, e.g. every weekday ("every one weekday") or every two weeks.'],
        ['#addTodoRepeatPrio', 'An optional priority for the task (series). It is used for the initial sorting.<br><br>' +
        'Any integer value can be used. Tasks are listed in ascending order of priority.'],
        ['#addTodoNotes', 'Any notes for the task. You can also use Markdown here. For example, lists with <code>*</code>.'],
        ['#addTodoModal #resetTodo', 'Reset all entries in this dialog to their original state.'],
        ['#addTodoModal .modal-footer .i18n-close', 'Close the input dialog. All entries will be discarded.'],
        ['#addTodoSubmit', 'Save the new task. '],
];
}

export function editModalTour_en() {
return [
        ['#editTodoModal .modal-content', 'The edit dialog. Works just like the dialog for new tasks.<br><br>' +
        'Only the action buttons are different.<br><br>' +
        'Note: If the task belongs to a series, you are initially only editing the single task. To edit the entire series, go to "Edit series".'],
        ['#deleteTodo.i18n-delete', 'Delete this task.'],
        ['#deleteTodoSeries.visible', 'Delete a task series. You can choose whether to delete all repetitions or only the selected and all following repetitions.'],
        ['#editTodoSeries.visible', 'Opens a new dialog to edit the entire series.'],
        ['#editTodoModal .modal-footer .i18n-close', 'Close the edit dialog, all changes will be discarded.'],
        ['#editTodoSubmit', 'Save changes.'],
];
}

export function menuTour_en() {
return [
        ['#hamburger-menu', 'This is the TIO menu. Here you will find some functions that are not needed very often.'],
        ['#tio-version', 'The current TIO version.<br><br>Currently, TIO is in the beta phase and some things do not work properly yet.'],
        ['#add-todo-button', 'Add a new task for today. Has the same function as clicking in today\'s card.<br><br>Alternatively, press the <b>+</b> key.'],
        ['#sync-save-button', 'Save tasks now.<br><br>Normally, you do not need to do this, as TIO automatically saves everything locally immediately.<br><br>If you link TIO with your Dropbox, the current state is also regularly saved there. If this does not work, you can try again here.'],
        ['#delete-all', 'Delete all (really all!) tasks.<br><br>Do this only if you know exactly what you are doing.'],
        ['#preferences', 'Here you can make various settings or enable/disable experimental features.'],
        ['body:not(.dropbox-connected) #dropbox-login-button', 'Link TIO with your Dropbox - as a backup and for synchronization between multiple devices.'],
        ['body.dropbox-connected #dropbox-logout-button', 'Disconnect Dropbox.'],
        ['body.dropbox-connected #dropbox-file', 'Select the Dropbox file where all TIO data will be saved.'],
        ['body:not(.google-connected) #google-login-button', 'Link TIO with your Google account and import your calendar events into TIO.<br><br>' +
        'This is a one-way sync from the calendar to TIO. Changes in the calendar are imported into TIO. If you change an event in TIO, it stays in TIO.<br><br>' +
        'After clicking "Connect", the Google login dialog will open immediately.'
        ],
        ['body.google-connected #google-logout-button', 'Disconnect Google.'],
        ['body.google-connected #google-calendar-list', 'Select calendars to integrate.'],
        ['#about-tio', 'The info dialog from the start. Here you can also reactivate the tour.'],
];
}

export function miniSyncTour_en() {
return [
        ['#small-status', 'When the status button scrolls out of view, this small version appears instead.'],
];
}

export function todoDetailsTour_en() {
return [
        ['.todo-popover .popover-header', 'These are the details of a task, the title is at the top.'],
        ['.todo-popover .popover-body', 'Here are all the notes and additional information of a task.'],
        ['.todo-popover .popover-header .bi-arrow-repeat', 'A series task is marked by a round arrow here at the top.'],
        ['.todo-popover .popover-header', 'These are the details of a task, the title is at the top.'],
        ['.todo-popover .popover-body .created-title', 'When was this task created?'],
        ['.todo-popover .popover-body .repeat-title', 'What is the repetition pattern of a task?'],
        ['.todo-popover .popover-body .original-created-title', 'When was the series created?'],
        ['.todo-popover .popover-footer .dropdown-toggle', 'There are several action buttons for a task. This arrow next to it expands more similar actions.'],
        ['.todo-popover .popover-footer .to-top', 'Move the task within its card.'],
        ['.todo-popover .popover-footer .btn-group>.plus-1w', 'Move the task to the next week.'],
        ['.todo-popover .popover-footer .btn-group>.plus-1d', 'Move the task to the next day.'],
        ['.todo-popover .popover-footer .to-0', 'Schedule the task for today.'],
        ['.todo-popover .popover-footer .clone', 'Create a 1:1 copy of this task.'],
        ['.todo-popover .popover-footer .edit', 'Edit this task. For series tasks, there is also the option to edit the series.'],
        ['.todo-popover .popover-footer .delete', 'Delete this (single) task.'],
];
}