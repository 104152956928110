import {startOfDay, endOfDay, endOfMonth, endOfWeek, endOfYear, millisPerDay, startOfWeek, fmtDate, refmtTime, typeFmtDate} from './dates';
import { isEnabled } from './features';
import { cloneRepeated, itemEndDate, setPriority} from './items';

const typeOrderNumbers = {
  eventually: -1,
  unknown: 0,
  day: 1,
  weekday: 1,
  week: 2,
  month: 3,
  year: 4
};

export function typeOrder(item) {
  let type = 'unknown';
  if (item.due) {
    type = item.due.type;
  }
  const to = typeOrderNumbers[type];
  return to;
}

export class Bucket {
  constructor(start, target, title, date, type, num = 1, reference = undefined) {
    if (reference) {
      reference = startOfDay(reference);
    } else {
      reference = new Date(0);
    }
    this.target = target;
    this.title = title;
    if (type === 'day') {
      this.startDate = startOfDay(new Date(date));
      this.endDate = endOfDay(new Date(date), num - 1);
    } else if (type === 'week') {
      this.startDate = startOfDay(new Date(date));
      this.endDate = endOfWeek(date);
    } else if (type === 'month') {
      this.startDate = maxDate(startOfDay(new Date(date)), reference);
      this.endDate = endOfMonth(date);
    } else if (type === 'year') {
      this.startDate = maxDate(startOfDay(new Date(date)), reference);
      this.endDate = endOfYear(date);
    } else if (type === 'before') {
      this.endDate = endOfDay(new Date(date), -1);
    }
    this.type = type;
    this.typeOrder = typeOrderNumbers[type] || (typeOrderNumbers.year + 2);
    this.items = new Map();
    this.repeatedItems = new Set();
    this.newInstances = type === 'day' && new Date(this.startDate) >= startOfWeek(new Date());
    this.num = num;
    this.start = start; // start of the currently displayed week
    if (date) {
      this.bucketId = `${target}-${fmtDate(date)}`;
    } else {
      this.bucketId = `${target}-${fmtDate(start)}`;
    }
    this.weeklyOverdue = isEnabled('weekly-overdue');
  }

  clearItems() {
    this.items.clear();
  }
  weekdayMatchRepeat(daysBetween, every) {
    if (!this.startDate) {
      return false;
    }
    const weekday = this.startDate.getDay();
    if (weekday >= 1 && weekday <= 5) {
      if (daysBetween > 6) {
        const weeks = Math.round(daysBetween / 7);
        daysBetween -= weeks * 2;
      }
      if (daysBetween % every === 0) {
        return true;
      }
    }
    return false;
  }

  dayMatchRepeat(daysBetween, every) {
    if (typeOrderNumbers.day > this.typeOrder) {
      return false;
    }
    return daysBetween % every === 0;
  }

  weekMatchRepeat(daysBetween, every) {
    return daysBetween % (every * 7) === 0;
  }

  monthMatchRepeat(item) {
    const first = new Date(item.due.date);
    const then = new Date(this.startDate);
    const monthsBetween = (then.getFullYear() + then.getMonth()) - (first.getFullYear() + first.getMonth());
    return first.getDate() === then.getDate() && monthsBetween % item.repeat.every === 0;
  }

  yearMatchRepeat(item, daysBetween, every) {
    const first = new Date(item.due.date);
    const then = new Date(this.startDate);
    return first.getDate() === then.getDate() && first.getMonth() === then.getMonth() && (daysBetween / 365) % item.repeat.every === 0;
  }

  matches(item, alreadyShownSet) {
    if (this.target.endsWith('-strict') && (!item.due || this.type !== item.due.type)) {
      return false;
    }
    let check = item.id;
    if (item.instanceOf) {
      check = item.instanceOf;
    } else if (item.wasInstanceOf) {
      check = item.wasInstanceOf;
    }
    const endDate = itemEndDate(item);
    if (!item.done && this.type === 'before' && !item.repeat && endDate) {
      if (this.weeklyOverdue) {
        if (endDate <= this.endDate) {
          return true;
        }
      }
      // dynamisches overdue für mobile
      if (endDate < startOfDay(new Date()) && !alreadyShownSet.has(item.id)) {
        return true;
      }
    }
    if (this.typeOrder > typeOrder(item) && alreadyShownSet.has(check)) {
      return false;
    }
    if (item.due) {
      const dueDate = startOfDay(new Date(item.due.date));
      if (!item.repeat && 
        (!this.startDate || dueDate >= this.startDate) && dueDate <= this.endDate) {
        if (this.type === item.due.type) {
          return true;
        } else if (this.typeOrder >= typeOrder(item)) {
          // scope of this bucket is larger or equal to scope of item
          if (dueDate >= this.start) {
            // do not show items that are due before the current week
            return true;
          }
        }
        return false;
      } else if (item.repeat && this.startDate) {
        if (this.type === 'day' && this.num > 1) {
          for (let i = 0; i < this.num; i++) {
            if (this.dayMatches(item, startOfDay(this.startDate, i))) {
              return true;
            }
          }
        } else {
          return this.dayMatches(item, this.startDate);
        }
        const every = item.repeat.every;
        const first = dueDate;
        const then = new Date(this.startDate);
      }
    } else if (this.type === 'eventually') {
      return true;
    }
    return false;
  }

  dayMatches(item, dayToTest) {
    const reference = startOfDay(new Date(item.due.date));
    const scope = item.repeat.scope;
    const every = item.repeat.every;
    let daysBetween = Math.round((dayToTest.getTime() - reference.getTime()) / millisPerDay);
    if (dayToTest.getTime() >= reference.getTime()) {
      switch (scope) {
        case 'weekday':
          return this.weekdayMatchRepeat(daysBetween, every);
        case 'day':
          return this.dayMatchRepeat(daysBetween, every);
        case 'week':
          return this.weekMatchRepeat(daysBetween, every);
        case 'month':
          return this.monthMatchRepeat(item);
        case 'year':
          return this.yearMatchRepeat(item);
        default:
          throw "Unknown scope: " + scope;
      }
    }
  }

  addIfNoInstanceYet(item) {
    if (item.repeat) {
      if (this.newInstances && !this.repeatedItems.has(item.id)) {
        this.repeatedItems.add(item.id);
        let dueDate = this.startDate;
        if (this.type === 'day' && this.num > 1) {
          for (let i = 0; i < this.num; i++) {
            const day = startOfDay(this.startDate, i);
            if (this.dayMatches(item, day)) {
              dueDate = day;
              break;
            }
          }
        }
        item = cloneRepeated(item, dueDate, this.type);
      } else {
        return;
      }
    }
  
    if (item.instanceOf) {
      this.repeatedItems.add(item.instanceOf);
    } else if (item.wasInstanceOf) {
      this.repeatedItems.add(item.wasInstanceOf);
    }
    this.items.set(item.id, item);
  }

  getItemsOrdered() {
    const ordered = [];
    const unordered = [];
    this.items.forEach(item => {
      if (!item.deleted) {
        if (typeof(item.priority) === 'undefined') {
          if (item.repeatpriority) {
            item.priority = item.repeatpriority;
            delete item.repeatpriority;
            if (item.due && item.due.time) {
              // if there is a due time, sort by time
              unordered.push(item);
            } else {
              // sort by repeatpriority
              ordered.push(item);
            }
          } else {
            unordered.push(item);
          }
        } else {
          ordered.push(item);
        }
      }
    });
    const id = this.bucketId;
    sortBucket(this.type, ordered);

    unordered.forEach(item => {
      let insertAt = ordered.length;
      if (item.due && item.due.time) {
        insertAt = findPositionByTime(item.due.time, ordered);
      } else if (item.imported) {
        insertAt = 0;
      }
      insertItemAt(ordered, item, insertAt);
    });

    sortBucket(this.type, ordered);
    return ordered;
  }
}

function insertItemAt(list, item, insertAt) {
  const type = 'insert-' + insertAt;
  if (list.length === 0) {
    setPriority(item, 0, type);
    list.push(item);
  } else if (insertAt >= list.length) {
    const lastEl = list[list.length - 1];
    if (lastEl) {
      setPriority(item, lastEl.priority*1 + 1, type);
    }
    list.push(item);
  } else {
    setPriority(item, list[insertAt].priority*1, type);
    for (let i = insertAt; i < list.length; i++) {
      list[i].priority += 1;
    }
    list.splice(insertAt, 0, item);
  }
}

function sortBucket(type, ordered) {
  if (type === 'eventually') {
    ordered.sort(function (el1, el2) {
      return el1.priority - el2.priority;
    });
  } else {
    // sort by: type, date, priority (undone) or done date (done)
    ordered.sort(function (el1, el2) {
      const to1 = typeOrder(el1);
      const to2 = typeOrder(el2);
      if (to1 < to2) {
        return 1;
      } else if (to1 > to2) {
        return -1;
      }
      const date1 = typeFmtDate(el1.due.date, el1.due.type);
      const date2 = typeFmtDate(el2.due.date, el2.due.type);
      if (date1 < date2) {
        return -1;
      } else if (date1 > date2) {
        return 1;
      }
      if (el1.done && el2.done) {
        return el1.done - el2.done;
      } else if (el1.done) {
        return -1;
      } else if (el2.done) {
        return 1;
      }
      return el1.priority - el2.priority;
    });
  }
}

function findPositionByTime(time, items) {
  time = refmtTime(time);
  let timeFound = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.done && !item.deleted && item.due && item.due.time) {
      const itemTime = refmtTime(item.due.time);
      if (itemTime <= time) {
        // if the time of the iterated item is before or equal to desired time => maybe insert after iterated one (also try later)
        timeFound = i + 1;
      } else {
        // if the iterated time is after => insert here
        return i;
      }
    }
  }
  if (timeFound) {
    return timeFound;
  } else {
    return 0;
  }
}

function maxDate(date1, date2) {
  if (date1 > date2) {
    return date1;
  } else {
    return date2;
  }
}