
export const texts_de = {
    'Storing data ({0} kB) to dropbox ({1}).': 'Schreibe Daten ({0} kB) in Dropbox ({1}).',
    'Storing to dropbox failed. Trying again in {0} seconds': 'Speichern in Dropbox fehlgeschlagen. Neuer Versuch in {0} Sekunden.',
    'Reading data from dropbox ({0}).': 'Lese Daten aus Dropbox ({0}).',
    'Loading Google Calendar {0}': 'Lese Google Kalender {0}.',
    'Cleared {0} todos deleted more than 4 weeks ago.': '{0} vor 4 Wochen gelöschte Aufgaben entfernt.',
    'Cleared {0} todos done more than 8 weeks ago.': '{0} vor 8 Wochen erledigte Aufgaben entfernt.',
    'Nothing to clean up.': 'Nichts zum Aufräumen.',
    'Today': 'Heute',
    'This Week': 'Diese Woche',
    'Tomorrow': 'Morgen',
    'Yesterday': 'Gestern',
    'Monday': 'Montag',
    'Tuesday': 'Dienstag',
    'Wednesday': 'Mittwoch',
    'Thursday': 'Donnerstag',
    'Friday': 'Freitag',
    'Saturday': 'Samstag',
    'Sunday': 'Sonntag',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'May': 'Mai',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Okt',
    'Nov': 'Nov',
    'Dec': 'Dez',
    'Mon': 'Mo',
    'Tue': 'Di',
    'Wed': 'Mi',
    'Thu': 'Do',
    'Fri': 'Fr',
    'Sat': 'Sa',
    'Sun': 'So',
    'CW': 'KW',
    'calendar week': 'Kalenderwoche',
    'January': 'Januar',
    'February': 'Februar',
    'March': 'März',
    'April': 'April',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'August',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'Dezember',
    'Timer has completed.': 'Der Timer ist abgelaufen.',
    'settingsarea-experimental': 'Experimentell',
    'setting-calendar-delete-button': 'Kalendertermine löschen',
    'setting-weekly-overdue': 'Wöchentlich überfällig',
    'setting-delete-all': 'Alle Termine löschen',
    'setting-debug-prio': 'Debug-Modus Prioritäten',
    'setting-debug-properties': 'Debug-Modus Eigenschaften',
    'setting-essential-view': 'Hauptübersicht',
    'setting-repeated-timers': 'Regelmäßige Alarme',
    'setting-auto-tags': 'Automatische Tags',
    'settingsarea-connections': 'Verbindungen',
    'settingsarea-debug': 'Debug',
    'settingsarea-behavior': 'Verhalten',
    'setting-dropbox': 'Dropbox',
    'setting-google-calendar': 'Google Kalender',
    'setting-o365-calendar': 'O365 Kalender',
    'setting-mobile-console': 'Mobile Konsole',
    'week': 'Woche',
    'weeks': 'Wochen',
    'day': 'Tag',
    'days': 'Tage',
    'weekdays': 'Werktage',
    'month': 'Monat',
    'months': 'Monate',
    'every': 'alle',
    'every-day': 'jeden Tag',
    'every-weekday': 'jeden Werktag',
    'every-week': 'jede Woche',
    'every-month': 'jeden Monat'
};

export const ui_de = {
    '#today': texts_de.Today,
    '#tomorrow': texts_de.Tomorrow,
    '#monday': texts_de.Monday,
    '#tuesday': texts_de.Tuesday,
    '#wednesday': texts_de.Wednesday,
    '#thursday': texts_de.Thursday,
    '#friday': texts_de.Friday,
    '#saturday': texts_de.Saturday,
    '#sunday': texts_de.Sunday,
    '#overdue': 'Überfällig',
    '#this-week': 'Diese Woche',
    '#this-week-strict': 'Diese Woche',
    '#next-week': 'Nächste Woche',
    '#this-month': 'Diesen Monat',
    '#this-month-strict': 'Diesen Monat',
    '#this-year': 'Dieses Jahr',
    '#this-year-strict': 'Dieses Jahr',
    '#this-weekend': 'Wochenende',
    '#eventually': 'Irgendwann',
    '#preferences': 'Einstellungen',
    '#repeattimers': 'Regelmäßige Alarme',
    '#autotags': 'Automatische Tags',
    '#week-left:title': 'Vorherige Woche',
    '#week-right:title': 'Nächste Woche',
    '#week-today:title': 'Heute',
    '#to-search': 'Suchen',
    '#delete-all': 'Alles Löschen',
    '#add-todo-button': 'Neue Aufgabe',
    '#sync-save-button': 'Speichern',
    '#purge-todos-button': 'Aufgaben bereinigen',
    '#dropbox-login-button': 'Verbinden',
    '#dropbox-logout-button': 'Trennen',
    '#google-login-button': 'Verbinden',
    '#google-logout-button': 'Trennen',
    '#dropbox-file': 'Datei auswählen <small class="filename"></small>',
    '#google-calendar-list': 'Kalender auswählen <small class="calendars"></small>',
    '#about-tio': 'Über TIO',
    '#dropboxModalLabel': 'Dropbox verbinden',
    '#dropboxModal .modal-body p:nth-child(1)': 'Verbinde deine Dropbox, damit TIO alle Daten im Ordner <code>/apps/tio.one</code> speichern kann.',
    '#dropboxModal .modal-body p:nth-child(2)': 'So hast du ein Backup deiner Daten und kannst sie zwischen mehreren Geräten synchronisieren.',
    '#dropboxModal .modal-body p:nth-child(3)': 'Wenn du auf "Verbinden" klickst, wirst du zur Dropbox-Seite umgeleitet und um Erlaubnis gefragt.',
    '#delete-series-modal-label': 'Serie löschen',
    '#delete-series-modal .modal-body .before': 'Aufgabe',
    '#delete-series-modal .modal-body .after': 'mit Wiederholungen löschen:',
    '#delete-series-modal .modal-body option[value="future"]': 'Nur diese und spätere Wiederholungen löschen',
    '#delete-series-modal .modal-body option[value="all"]': 'Alle Wiederholungen löschen',
    '#delete-series-modal .modal-footer .modal-submit': 'Serie löschen',
    '#dropbox-file-modal-label': 'Dropbox Datei auswählen',
    '#dropbox-file-modal .modal-body p span:nth-of-type(1)': 'Wähle den Dateinamen für deine TIO Daten.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(2)': 'So kannst du mehrere Todo-Listen verwalten.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(3)': '(TIO Dateien werden unter <code>/apps/tio.one</code> gespeichert.)',
    '#dropbox-file-modal .modal-body label:nth-of-type(1)': 'Wähle einen existierenden Namen: <small>(und lade die Datei)</small>',
    '#dropbox-file-modal .modal-body label:nth-of-type(2)': 'oder nimm einen neuen: <small>(und speichere deine Aufgaben dort)</small>',
    '#dropbox-file-modal .modal-footer .modal-submit': 'Auswählen',
    '.modal-cancel': 'Abbrechen',
    '#connect-dropbox': 'Verbinden',
    '.i18n-day': 'am Tag',
    '.i18n-week': 'in der Woche',
    '.i18n-month': 'im Monat',
    '.i18n-year': 'im Jahr',
    '.i18n-days': 'Tage',
    '.i18n-weekdays': 'Werktage',
    '.i18n-weeks': 'Wochen',
    '.i18n-months': 'Monate',
    '.i18n-years': 'Jahre',
    '.i18n-one': 'ein',
    '.i18n-two': 'zwei',
    '.i18n-three': 'drei',
    '.i18n-four': 'vier',
    '.i18n-five': 'fünf',
    '.i18n-six': 'sechs',
    '.i18n-duration': 'Dauer',
    '.i18n-notes': 'Notizen',
    '.i18n-due': 'Fällig',
    '.i18n-repeat-every': 'Wiederholen alle',
    '.i18n-sync': 'Synchronisieren',
    '.i18n-sync-data': 'Synchronisiere Daten...',
    '.i18n-loading': 'Lade...',
    '#invalid-feedback-duration': 'Dauer eingeben als Zahl und Einheit, z.B. <code>10m</code>',
    '#invalid-feedback-title': 'Bitte einen Titel eingeben',
    '#invalid-feedback-time': 'Datum als <code>yyyy-MM-dd</code> (Jahr, Monat, Tag) und Zeit als <code>HH:mm</code> (Stunden, Minuten)',
    '#invalid-feedback-prio': 'Priorität als max. dreistellige, positive oder negative Zahl',
    '#titleHelp': 'Titel, kann Markdown enthalten:  <code>**</code>, <code>__</code>, <code>[]()</code>',
    '.i18n-close': 'Schließen',
    '.i18n-delete': 'Löschen',
    '.i18n-delete-series': 'Serie löschen',
    '.i18n-edit-series': 'Serie bearbeiten',
    '.i18n-submit': 'Eintragen',
    '.i18n-title': 'Titel',
    '#addTodoModalLabel': 'Neue Aufgabe',
    '#editTodoModalLabel': 'Aufgabe bearbeiten',
    '.todo-popover .edit': 'Ändern',
    '.todo-popover .delete': 'Löschen',
    '.todo-popover .to-today:title': 'Auf heute schieben',
    '.todo-popover .next-day:title': 'Auf nächsten Tag schieben',
    '.todo-popover .next-week:title': 'In nächste Woche schieben',
    '.todo-popover .next-weekend:title': 'Auf nächstes Wochenende schieben',
    '.todo-popover .clone:title': 'Aufgabe duplizieren',
    '.todo-popover .clone': 'Kopieren',
    '#editTodoNotes:placeholder': 'Beliebige Markdown Notizen...',
    '#addTodoNotes:placeholder': 'Beliebige Markdown Notizen...',
    '#resetTodo': 'Zurücksetzen',
    '#purge-modal-label': 'Alte Daten löschen?',
    '#purge-modal .modal-body p:nth-of-type(1)': 'Es gibt viele Aufgaben, die schon lange erledigt oder gelöscht sind. Die brauchst du wahrscheinlich nicht mehr und sie verschwenden Platz.',
    '#purge-modal .modal-body p:nth-of-type(2)': 'Können wir solche Aufgaben (vor 4 Wochen gelöscht oder vor 8 Wochen erledigt) regelmäßig entfernen?',
    '#purge-modal-submit': 'Entfernen',
    '.due-title': 'Fällig',
    '.duration-title': 'Dauer',
    '.created-title': 'Erzeugt',
    '.done-title': 'Erledigt',
    '.imported-title': 'Importiert von',
    '.repeat-title': 'Wiederholung',
    '.repeat-was-title': 'Wiederholung war',
    '.original-created-title': 'Original erzeugt',
    '#intro-modal-label': 'TIO Planer',
    '#intro-modal .jumbotron .lead': '<b>TIO ist ein Aufgabenplaner<br/>auf Wochenbasis, offline first</b>',
    '#intro-modal .jumbotron li:nth-of-type(1)': 'Alle Aufgaben einer Woche auf einen Blick.',
    '#intro-modal .jumbotron li:nth-of-type(2)': 'Behalte auch überfällige Aufgaben im Auge.',
    '#intro-modal .jumbotron li:nth-of-type(3)': 'Alle Daten bleiben in deinem Browser.',
    '#intro-modal .jumbotron li:nth-of-type(4)': 'Formatiere deinen Text mit Markdown.',
    '#intro-modal .jumbotron li:nth-of-type(5)': '<em>Optional</em>: Synchronisiere Aufgaben in deine Cloud.',
    '#intro-modal .jumbotron li:nth-of-type(6)': '<em>Optional</em>: Integriere Kalender-Termine.',
    '#intro-modal .tourinfo': 'Wenn du möchtest, erklären wir dir TIOs Funktionen, wenn sie erstmals im Bild sind.',
    '#intro-modal-submit': 'Ja, ich möchte eine Tour!',
    '#intro-modal-cancel': 'Nein, ich brauche keine Tour.',
    '#unclear-sync-modal-submit': 'Lokale Daten',
    '#unclear-sync-modal-cancel': 'Remote Daten',
    '#preferences-modal-label': 'Einstellungen',
    '#preferences-modal p span': 'Funktionen ein- oder ausschalten:',
    '#preferences-modal-submit': 'Übernehmen',
    '.tag-button.untagged': 'Ohne Tag',
};

