import { millisPerMinute } from "./dates";
import { translate } from "./i18n";

let timer;
let timerEnd;
let timerRemaining;
export function startTimer(minutes) {
  clearTimer();
  timerRemaining = null;
  const $timer = document.querySelector('#timer .running-timer');
  $timer.innerText = '--:--';
  $timer.parentElement.classList.remove('d-none');

  timerEnd = new Date().getTime() + minutes * millisPerMinute;
  updateTimer();
  timer = setInterval(updateTimer, 1000);
}

export function toggleTimer() {
  if (timerRemaining) {
    continueTimer();
  } else {
    pauseTimer();
  }
}

function pauseTimer() {
  const $timer = document.querySelector('#timer .running-timer');
  $timer.innerText = 'PAUSE';
  timerRemaining = timerEnd - new Date().getTime();
  clearTimer();
}

function continueTimer() {
  const $timer = document.querySelector('#timer .running-timer');
  timerEnd = new Date().getTime() + timerRemaining;
  timerRemaining = null;
  updateTimer();
  timer = setInterval(updateTimer, 1000);
}

function updateTimer() {
  const secondsToGo = (timerEnd - new Date().getTime()) / 1000;
  let show;
  if (secondsToGo <= 0) {
    show = '0:00';
    clearTimer();
  } else {
    const minutes = Math.floor(secondsToGo / 60);
    let seconds = Math.round(secondsToGo % 60);
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    show = `${minutes}:${seconds}`;
  }
  const $timer = document.querySelector('#timer .running-timer');
  $timer.innerText = show;
  if (secondsToGo <= 0) {
    const alarm = new Audio('/alarm.mp3');
    alarm.play();
    $timer.parentElement.classList.add('d-none');
    alert(translate('Timer has completed.'));
    alarm.pause();
  }
}

function clearTimer() {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
}