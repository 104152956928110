import { millisPerDay } from './dates';
import { tioVersion } from './version';
export function initUpdater() {
  const alert = document.createElement('div');
  alert.classList.add('alert', 'alert-info', 'd-none');
  alert.id = 'update-alert';
  alert.innerHTML = `A new TIO version <span id="update-version-info"></span> is available!
  <a id="update-button" class="btn btn-small p-1 mb-2 btn-warning" href="#">Click here to update</a>
  <button type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>`;
  const content = document.querySelector('.swiper');
  content.parentNode.insertBefore(alert, content);
  document.querySelector('#update-alert .close').addEventListener('click', function () {
    document.getElementById('update-alert').classList.add('d-none');
  });
  updateCheck();
  setInterval(updateCheck, millisPerDay);
}

function updateCheck() {
  fetch('/version.json?v=' + new Date().getTime())
    .then(response => response.json())
    .then(versionObject => compareVersion(versionObject));
}

function compareVersion(versionObject) {
  const thatVersion = versionObject.version;
  if (thatVersion !== tioVersion) {
    const info = `${tioVersion} -> ${thatVersion}`;
    document.getElementById('update-version-info').innerText = info;
    document.getElementById('update-button').href = window.location.href.split('/').slice(0, 3).join('/') + "/?v=" + thatVersion;
    document.getElementById('update-alert').classList.remove('d-none');
  }
}
