export const texts_fr = {
    'Storing data ({0} kB) to dropbox ({1}).': 'Enregistrement des données ({0} kB) sur Dropbox ({1}).',
    'Storing to dropbox failed. Trying again in {0} seconds': 'Échec de l\'enregistrement sur Dropbox. Nouvel essai dans {0} secondes.',
    'Reading data from dropbox ({0}).': 'Lecture des données depuis Dropbox ({0}).',
    'Loading Google Calendar {0}': 'Chargement du calendrier Google {0}.',
    'Cleared {0} todos deleted more than 4 weeks ago.': '{0} tâches supprimées il y a plus de 4 semaines effacées.',
    'Cleared {0} todos done more than 8 weeks ago.': '{0} tâches effectuées il y a plus de 8 semaines effacées.',
    'Nothing to clean up.': 'Rien à nettoyer.',
    'Today': 'Aujourd\'hui',
    'This Week': 'Cette semaine',
    'Tomorrow': 'Demain',
    'Yesterday': 'Hier',
    'Monday': 'Lundi',
    'Tuesday': 'Mardi',
    'Wednesday': 'Mercredi',
    'Thursday': 'Jeudi',
    'Friday': 'Vendredi',
    'Saturday': 'Samedi',
    'Sunday': 'Dimanche',
    'Jan': 'Jan',
    'Feb': 'Fév',
    'Mar': 'Mar',
    'Apr': 'Avr',
    'May': 'Mai',
    'Jun': 'Juin',
    'Jul': 'Juil',
    'Aug': 'Aoû',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Déc',
    'Mon': 'Lun',
    'Tue': 'Mar',
    'Wed': 'Mer',
    'Thu': 'Jeu',
    'Fri': 'Ven',
    'Sat': 'Sam',
    'Sun': 'Dim',
    'CW': 'SW',
    'calendar week': 'semaine calendaire',
    'January': 'Janvier',
    'February': 'Février',
    'March': 'Mars',
    'April': 'Avril',
    'June': 'Juin',
    'July': 'Juillet',
    'August': 'Août',
    'September': 'Septembre',
    'October': 'Octobre',
    'November': 'Novembre',
    'December': 'Décembre',
    'Timer has completed.': 'Le minuteur est terminé.',
    'settingsarea-experimental': 'Expérimental',
    'setting-calendar-delete-button': 'Supprimer les événements du calendrier',
    'setting-weekly-overdue': 'Hebdomadaire en retard',
    'setting-delete-all': 'Supprimer tous les événements',
    'setting-debug-prio': 'Mode débogage priorités',
    'setting-debug-properties': 'Mode débogage propriétés',
    'setting-essential-view': 'Vue essentielle',
    'setting-repeated-timers': 'Alarmes répétées',
    'setting-auto-tags': 'Tags automatiques',
    'settingsarea-connections': 'Connexions',
    'settingsarea-debug': 'Débogage',
    'settingsarea-behavior': 'Comportement',
    'setting-dropbox': 'Dropbox',
    'setting-google-calendar': 'Calendrier Google',
    'setting-o365-calendar': 'Calendrier O365',
    'setting-mobile-console': 'Console mobile',
    'week': 'semaine',
    'weeks': 'semaines',
    'day': 'jour',
    'days': 'jours',
    'weekdays': 'jours de semaine',
    'month': 'mois',
    'months': 'mois',
    'every': 'chaque',
    'every-day': 'chaque jour',
    'every-weekday': 'chaque jour de semaine',
    'every-week': 'chaque semaine',
    'every-month': 'chaque mois'
};

export const ui_fr = {
    '#today': texts_fr.Today,
    '#tomorrow': texts_fr.Tomorrow,
    '#monday': texts_fr.Monday,
    '#tuesday': texts_fr.Tuesday,
    '#wednesday': texts_fr.Wednesday,
    '#thursday': texts_fr.Thursday,
    '#friday': texts_fr.Friday,
    '#saturday': texts_fr.Saturday,
    '#sunday': texts_fr.Sunday,
    '#overdue': 'En retard',
    '#this-week': 'Cette semaine',
    '#this-week-strict': 'Cette semaine',
    '#next-week': 'La semaine prochaine',
    '#this-month': 'Ce mois-ci',
    '#this-month-strict': 'Ce mois-ci',
    '#this-year': 'Cette année',
    '#this-year-strict': 'Cette année',
    '#this-weekend': 'Ce week-end',
    '#eventually': 'Un jour',
    '#preferences': 'Préférences',
    '#repeattimers': 'Alarmes répétées',
    '#autotags': 'Tags automatiques',
    '#week-left:title': 'Semaine précédente',
    '#week-right:title': 'Semaine suivante',
    '#week-today:title': 'Aujourd\'hui',
    '#to-search': 'Rechercher',
    '#delete-all': 'Tout supprimer',
    '#add-todo-button': 'Nouvelle tâche',
    '#sync-save-button': 'Enregistrer',
    '#purge-todos-button': 'Nettoyer les tâches',
    '#dropbox-login-button': 'Connecter',
    '#dropbox-logout-button': 'Déconnecter',
    '#google-login-button': 'Connecter',
    '#google-logout-button': 'Déconnecter',
    '#dropbox-file': 'Sélectionner un fichier <small class="filename"></small>',
    '#google-calendar-list': 'Sélectionner un calendrier <small class="calendars"></small>',
    '#about-tio': 'À propos de TIO',
    '#dropboxModalLabel': 'Connecter Dropbox',
    '#dropboxModal .modal-body p:nth-child(1)': 'Connectez votre Dropbox pour que TIO puisse enregistrer toutes les données dans le dossier <code>/apps/tio.one</code>.',
    '#dropboxModal .modal-body p:nth-child(2)': 'Ainsi, vous aurez une sauvegarde de vos données et pourrez les synchroniser entre plusieurs appareils.',
    '#dropboxModal .modal-body p:nth-child(3)': 'En cliquant sur "Connecter", vous serez redirigé vers la page Dropbox pour demander l\'autorisation.',
    '#delete-series-modal-label': 'Supprimer la série',
    '#delete-series-modal .modal-body .before': 'Tâche',
    '#delete-series-modal .modal-body .after': 'avec répétitions à supprimer :',
    '#delete-series-modal .modal-body option[value="future"]': 'Supprimer uniquement cette répétition et les suivantes',
    '#delete-series-modal .modal-body option[value="all"]': 'Supprimer toutes les répétitions',
    '#delete-series-modal .modal-footer .modal-submit': 'Supprimer la série',
    '#dropbox-file-modal-label': 'Sélectionner un fichier Dropbox',
    '#dropbox-file-modal .modal-body p span:nth-of-type(1)': 'Choisissez le nom du fichier pour vos données TIO.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(2)': 'Ainsi, vous pouvez gérer plusieurs listes de tâches.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(3)': '(Les fichiers TIO sont enregistrés sous <code>/apps/tio.one</code>.)',
    '#dropbox-file-modal .modal-body label:nth-of-type(1)': 'Choisissez un nom existant : <small>(et chargez le fichier)</small>',
    '#dropbox-file-modal .modal-body label:nth-of-type(2)': 'ou utilisez un nouveau : <small>(et enregistrez vos tâches là-bas)</small>',
    '#dropbox-file-modal .modal-footer .modal-submit': 'Sélectionner',
    '.modal-cancel': 'Annuler',
    '#connect-dropbox': 'Connecter',
    '.i18n-day': 'par jour',
    '.i18n-week': 'par semaine',
    '.i18n-month': 'par mois',
    '.i18n-year': 'par an',
    '.i18n-days': 'jours',
    '.i18n-weekdays': 'jours de semaine',
    '.i18n-weeks': 'semaines',
    '.i18n-months': 'mois',
    '.i18n-years': 'années',
    '.i18n-one': 'un',
    '.i18n-two': 'deux',
    '.i18n-three': 'trois',
    '.i18n-four': 'quatre',
    '.i18n-five': 'cinq',
    '.i18n-six': 'six',
    '.i18n-duration': 'Durée',
    '.i18n-notes': 'Notes',
    '.i18n-due': 'Échéance',
    '.i18n-repeat-every': 'Répéter tous les',
    '.i18n-sync': 'Synchroniser',
    '.i18n-sync-data': 'Synchronisation des données...',
    '.i18n-loading': 'Chargement...',
    '#invalid-feedback-duration': 'Entrez la durée sous forme de nombre et d\'unité, par ex. <code>10m</code>',
    '#invalid-feedback-title': 'Veuillez entrer un titre',
    '#invalid-feedback-time': 'Entrez la date sous la forme <code>yyyy-MM-dd</code> (année, mois, jour) et l\'heure sous la forme <code>HH:mm</code> (heures, minutes)',
    '#invalid-feedback-prio': 'Priorité sous forme de nombre positif ou négatif à trois chiffres maximum',
    '#titleHelp': 'Titre, peut contenir du Markdown :  <code>**</code>, <code>__</code>, <code>[]()</code>',
    '.i18n-close': 'Fermer',
    '.i18n-delete': 'Supprimer',
    '.i18n-delete-series': 'Supprimer la série',
    '.i18n-edit-series': 'Modifier la série',
    '.i18n-submit': 'Soumettre',
    '.i18n-title': 'Titre',
    '#addTodoModalLabel': 'Nouvelle tâche',
    '#editTodoModalLabel': 'Modifier la tâche',
    '.todo-popover .edit': 'Modifier',
    '.todo-popover .delete': 'Supprimer',
    '.todo-popover .to-today:title': 'Déplacer à aujourd\'hui',
    '.todo-popover .next-day:title': 'Déplacer au jour suivant',
    '.todo-popover .next-week:title': 'Déplacer à la semaine suivante',
    '.todo-popover .next-weekend:title': 'Déplacer au week-end suivant',
    '.todo-popover .clone:title': 'Dupliquer la tâche',
    '.todo-popover .clone': 'Copier',
    '#editTodoNotes:placeholder': 'Notes Markdown...',
    '#addTodoNotes:placeholder': 'Notes Markdown...',
    '#resetTodo': 'Réinitialiser',
    '#purge-modal-label': 'Supprimer les anciennes données ?',
    '#purge-modal .modal-body p:nth-of-type(1)': 'Il y a beaucoup de tâches qui sont terminées ou supprimées depuis longtemps. Vous n\'en avez probablement plus besoin et elles prennent de la place.',
    '#purge-modal .modal-body p:nth-of-type(2)': 'Pouvons-nous supprimer régulièrement ces tâches (supprimées il y a plus de 4 semaines ou terminées il y a plus de 8 semaines) ?',
    '#purge-modal-submit': 'Supprimer',
    '.due-title': 'Échéance',
    '.duration-title': 'Durée',
    '.created-title': 'Créé',
    '.done-title': 'Terminé',
    '.imported-title': 'Importé de',
    '.repeat-title': 'Répétition',
    '.repeat-was-title': 'Répétition était',
    '.original-created-title': 'Créé à l\'origine',
    '#intro-modal-label': 'Planificateur TIO',
    '#intro-modal .jumbotron .lead': '<b>TIO est un planificateur de tâches<br/>hebdomadaire, d\'abord hors ligne</b>',
    '#intro-modal .jumbotron li:nth-of-type(1)': 'Toutes les tâches de la semaine en un coup d\'œil.',
    '#intro-modal .jumbotron li:nth-of-type(2)': 'Gardez un œil sur les tâches en retard.',
    '#intro-modal .jumbotron li:nth-of-type(3)': 'Toutes les données restent dans votre navigateur.',
    '#intro-modal .jumbotron li:nth-of-type(4)': 'Formatez votre texte avec Markdown.',
    '#intro-modal .jumbotron li:nth-of-type(5)': '<em>Optionnel</em> : Synchronisez les tâches dans votre cloud.',
    '#intro-modal .jumbotron li:nth-of-type(6)': '<em>Optionnel</em> : Intégrez les événements du calendrier.',
    '#intro-modal .tourinfo': 'Si vous le souhaitez, nous vous expliquerons les fonctionnalités de TIO lorsqu\'elles apparaîtront pour la première fois.',
    '#intro-modal-submit': 'Oui, je veux une visite guidée !',
    '#intro-modal-cancel': 'Non, je n\'ai pas besoin de visite guidée.',
    '#unclear-sync-modal-submit': 'Données locales',
    '#unclear-sync-modal-cancel': 'Données distantes',
    '#preferences-modal-label': 'Préférences',
    '#preferences-modal p span': 'Activer ou désactiver les fonctionnalités :',
    '#preferences-modal-submit': 'Appliquer',
    '.tag-button.untagged': 'Sans tag',
};