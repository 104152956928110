import { isMobile } from "./browser";
import { translate } from "./i18n";

export const millisPerMinute = 60000;
export const millisPerHour = millisPerMinute * 60;
export const millisPerDay = millisPerHour * 24;
export const millisPerWeek = millisPerDay * 7;

const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export function plusDate(dateString, plus) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + plus);
  return fmtDate(date);
}

export function durationToMinutes(todo) {
  const type = typeof(todo);
  let duration;
  if (type === 'object') {
    if (!todo.duration) {
      return 0;
    }
    duration = todo.duration;
  } else {
    duration = todo;
  }
  let num = parseInt(duration.match(/\d+/)[0]);
  const unit = duration.match(/[^\d]+/)[0].toLowerCase();
  switch (unit) {
    case 'h':
      num = num * 60;
      break;
    case 'd':
      num = num * 60 * 24;
      break;
  }
  return num;
}

export function isDateElapsed(dateString) {
  return new Date(dateString).getTime() < startOfDay(new Date(), 0).getTime();
}

export function isToday(date) {
  return isDay(date, new Date());
}

export function isDay(checkDate, referenceDate) {
  checkDate = new Date(checkDate);
  referenceDate = new Date(referenceDate);
  const refStart = startOfDay(referenceDate);
  const refEnd = endOfDay(referenceDate);
  return checkDate >= refStart && checkDate <= refEnd;
}

export function isDueSoon(date, time) {
  date = setTime(new Date(date), time);
  date = new Date(date.getTime() - 15 * millisPerMinute);
  const now = new Date();
  return now > date;
}

export function isDue(date, time) {
  date = setTime(new Date(date), time);
  const now = new Date();
  return now > date;
}

export function isOver(date, time, duration) {
  date = setTime(new Date(date), time);
  if (duration) {
    date = new Date(date.getTime() + durationToMinutes(duration) * millisPerMinute);
  }
  const now = new Date();
  return now > date;
}

export function setTime(date, time) {
  const colon = time.indexOf(':');
  const hours = parseInt(time.substring(0, colon));
  const minutes = parseInt(time.substring(colon + 1));
  date = new Date(date);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  return date;
}

export function isWithinTwoWeeks(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const startOfThisWeek = startOfWeek(today);
  const endOfNextWeek = endOfWeek(plusDate(today, 7));
  return date >= startOfThisWeek && date <= endOfNextWeek;
}

export function isDateNear(dateString, type) {
  let nearDate = new Date(dateString);
  let today = new Date();
  let maxDays = 1;
  switch (type) {
    case 'week':
      nearDate = endOfWeek(nearDate);
      maxDays = 3;
      break;
    case 'month':
      nearDate = endOfMonth(nearDate);
      maxDays = 8;
      break;
    case 'year':
      nearDate = endOfYear(nearDate);
      maxDays = 60;
      break;
  }

  const diffDays = (nearDate.getTime() - today.getTime()) / (millisPerDay);
  return diffDays < maxDays;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export function fmtDay(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = days[date.getDay()];
  return translate(day);
}

export function fmtDayShort(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = daysShort[date.getDay()];
  return translate(day);
}

export function typeFmtDate(date, type) {
  if (type === 'week') {
    date = startOfWeek(date);
  } else if (type === 'month') {
    date = startOfMonth(date);
  }
  const fmt = fmtDate(date);
  return fmt;
}

export function fmtDate(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + '-' + String(month).padStart(2, '0') + '-' + String(day).padStart(2, '0');
}

export function fmtTime(time) {
  if (!time) {
    return null;
  }
  if (typeof(time) === 'string') {
    return refmtTime(time);
  }
  time = new Date(time);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  return time2str(hours, minutes);
}

function time2str(hours, minutes) {
  const str = String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
  return str;
}

export function refmtTime(time) {
  const t = time.indexOf('T');
  if (t > 0) {
    time = time.substring(t+1);
  }
  const colon = time.indexOf(':');
  const hours = parseInt(time.substring(0, colon));
  const minutes = parseInt(time.substring(colon + 1));
  if (isNaN(hours) || isNaN(minutes)) {
    return '';
  }
  return time2str(hours, minutes);
}

export function dayFromNow(days) {
  return plusDay(new Date(), days);
}

export function plusMinute(date, plus = 1) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  return new Date(date.getTime() + plus * millisPerMinute);
}

export function plusDay(date, plus = 1) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  return new Date(date.getTime() + plus * millisPerDay);
}

export function plusWeek(date, plus = 1) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  return new Date(date.getTime() + plus * 7 * millisPerDay);
}

export function startOfDay(date, add = 0) {
  const day = new Date(date.getTime() + add * millisPerDay);
  day.setHours(0, 0, 0, 0);
  return day;
}

export function endOfDay(date, add = 0) {
  const day = new Date(date.getTime() + add * millisPerDay);
  day.setHours(23, 59, 59, 999);
  return day;
}

export function startOfWeek(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = new Date(date.getTime());
  const weekday = day.getDay();
  if (weekday === 0) {
    day.setDate(day.getDate() - 6);
  } else if (weekday > 1) {
    day.setDate(day.getDate() - weekday + 1);
  }
  return startOfDay(day);
}

export function endOfWeek(date) {
  const day = startOfWeek(date);
  day.setDate(day.getDate() + 6);
  return endOfDay(day);
}

export function startOfMonth(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = new Date(date.getTime());
  day.setDate(1);
  return startOfDay(day);
}

export function endOfMonth(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = new Date(date.getTime());
  day.setMonth(day.getMonth() + 1);
  day.setDate(0);
  return endOfDay(day);
}

export function startOfYear(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = new Date(date.getTime());
  day.setMonth(0);
  day.setDate(1);
  return startOfDay(day);
}

export function endOfYear(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const day = new Date(date.getTime());
  day.setFullYear(day.getFullYear() + 1);
  day.setMonth(0);
  day.setDate(0);
  return endOfDay(day);
}


export function titleDateDay(date, num = 1) {
  if (!date) {
    return '';
  }
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  let title = date.getDate() + '.' + (date.getMonth()+1) + '.';
  if (num > 1) {
    let nextDate = new Date(date.getTime() + (num-1) * millisPerDay);
    title += `-${nextDate.getDate()}.${nextDate.getMonth()+1}.`;
  }
  return title;
}

export function infoDateDay(date) {
  if (!date) {
    return '';
  }
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  if (isToday(date)) {
    return translate("Today");
  } else if (isDay(date, plusDay(new Date(), 1))) {
    return translate("Tomorrow");
  } else if (isDay(date, plusDay(new Date(), 1))) {
    return translate("Yesterday");
  }
  let title = titleDateDay(date);
  return title;
}

export function titleDateWeek(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const start = startOfWeek(date);
  const end = endOfWeek(date);
  return `${translate('CW')} ${calweek(start)}: ${start.getDate()}.${start.getMonth()+1}.-${end.getDate()}.${end.getMonth()+1}.`;
}

export function cw(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  const start = startOfWeek(date);
  const end = endOfWeek(date);
  let startMonth;
  let endMonth;
  if (isMobile()) {
    startMonth = (start.getMonth() + 1) + '.';
    endMonth = (end.getMonth() + 1) + '.';
  } else {
    startMonth = ' ' + translate(months[start.getMonth()]) + ' ';
    endMonth = ' ' + translate(months[end.getMonth()]) + ' ';  
  }
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();
  let title;
  if (startYear === endYear) {
    if (startMonth === endMonth) {
      title = `${start.getDate()}. - ${end.getDate()}.${endMonth} ${endYear}`;
    } else {
      title = `${start.getDate()}.${startMonth} - ${end.getDate()}.${endMonth} ${endYear}`;
    }
  } else {
    title = `${start.getDate()}.${startMonth}${startYear} - ${end.getDate()}.${endMonth}${endYear}`;
  }
  const week = calweek(date);
  return `<span class="calendar-week" title="${translate('calendar week')} ${week}">${week}</span> ${title}`;
}

function calweek(date) {
  const currentThursday = new Date(date.getTime() +(3-((date.getDay()+6) % 7)) * 86400000);
  const yearOfThursday = currentThursday.getFullYear();
  const firstThursday = new Date(new Date(yearOfThursday,0,4).getTime() +(3-((new Date(yearOfThursday,0,4).getDay()+6) % 7)) * 86400000);
  const weekNumber = Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000/7);
  return weekNumber;
}

export function titleDateMonth(date) {
  if (typeof(date) === 'string') {
    date = new Date(date);
  }
  return translate(monthsShort[date.getMonth()]) + ' ' + date.getFullYear();
}

export function titleDateYear(date) {
  return date.getFullYear();
}

export function isFriday(date) {
  date = new Date(date);
  return date.getDay() === 5;
}

export function isNotWeekend(date) {
  date = new Date(date);
  const day = date.getDay();
  return day !== 6 && day !== 0;
}

export function isThisWeek(date) {
  date = new Date(date);
  const today = startOfDay(new Date());
  const monday = startOfWeek(today);
  const sunday = endOfWeek(today);
  let thisWeek = false;
  if (date >= monday && date <= sunday) {
    thisWeek = true;
  }
  return thisWeek;
}