import StackTrace from 'stacktrace-js';

export function trycatch(closure) {
  try {
    closure();
  } catch (exc) {
    console.log(exc);
    alertWithStackTrace(exc);
  }
}

function alertWithStackTrace(info) {
  StackTrace.get().then(stackframes => {
    let stringifiedStack = stackframes.map(function(sf) {
      return sf.toString();
    }).join('\n');
    alert(info + '\n\n' + stringifiedStack);
  });
}
