
export function setBadge(count) {
  if (navigator.setAppBadge && navigator.clearAppBadge) { // only if badge is supported
    if (!("Notification" in window)) {
      // not supported, ignore
    } else if (Notification.permission === "granted") {
      doSetBadge(count);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          doSetBadge(count);
        }
      });
    }
  }
}

function doSetBadge(count) {
  if (count === 0) {
    navigator.clearAppBadge();
  } else {
    navigator.setAppBadge(count);
  }
}
